import {
  PAGE_LOAD,
  AUTHENTICATION,
  AUTH_TOKEN_EXPIRED,
  LOGIN,
  CREATE_ACCOUNT,
  VALUE_PROPOSITION,
  ORGANIC_LANDING,
  PROPERTY_DISABLED,
  GET_STARTED_BUTTON,
  LOGIN_BUTTON,
  CLICK,
  SIGN_UP_BUTTON,
  NEXT_BUTTON,
  VALUE_PROPOSITION_BUTTON,
  LETS_GO_BUTTON,
  BACK_BUTTON,
  FORGOT_PASSWORD_BUTTON,
  EMAIL_CONFIRM_PAGE,
  BACK_TO_SIGN_IN_BUTTON,
  SENT_MAGIC_LINK,
  SEND_MAGIC_LINK_BUTTON,
  RESEND_MAGIC_LINK_BUTTON,
  LANDING_CREATE_ACCOUNT,
  SEND_MAGIC_LINK_CREATE_ACCOUNT_BUTTON,
  LANDING_PROPERTY_PICKER,
} from "./constants";
import { getOrSetCookie, getExperiments } from "./utils";

export function authenticationAppTrackingAttributes(props) {
  const timeStamp = new Date().toUTCString();
  return {
    page: AUTHENTICATION,
    event: PAGE_LOAD,
    session_id: getOrSetCookie(),
    event_timestamp: timeStamp,
    context: AUTHENTICATION,
    player_id: props.playerId,
    resident_id: props.residentId,
    user_id: props.userId,
    data: {
      hasUser: props.hasUser,
      email: props.email,
      hasIncentive: props.hasIncentive,
    },
    experiments: getExperiments(),
    railsContext: props.railsContext,
  };
}

export function PropertyDisabledTrackingAttributes() {
  return {
    page: PROPERTY_DISABLED,
    event: PAGE_LOAD,
    context: PROPERTY_DISABLED,
  };
}

export function organicLandingTrackingAttributes() {
  return {
    page: ORGANIC_LANDING,
    event: PAGE_LOAD,
    context: ORGANIC_LANDING,
  };
}

export function organicLandingGetStartedTrackingAttributes() {
  return {
    page: ORGANIC_LANDING,
    event: CLICK,
    context: GET_STARTED_BUTTON,
  };
}

export function organicLandingLoginButtonTrackingAttributes() {
  return {
    page: ORGANIC_LANDING,
    event: CLICK,
    context: LOGIN_BUTTON,
  };
}

export function organicLandingValuePropButtonTrackingAttributes() {
  return {
    page: ORGANIC_LANDING,
    event: CLICK,
    context: VALUE_PROPOSITION_BUTTON,
  };
}

export function loginPageLoadTrackingAttributes() {
  return {
    page: LOGIN,
    event: PAGE_LOAD,
    context: LOGIN,
  };
}

export function loginAutoLoginLinkButtonTrackingAttributes() {
  return {
    page: LOGIN,
    event: CLICK,
    context: SEND_MAGIC_LINK_BUTTON,
  };
}

export function loginAuthTokenExpiredPageLoadTrackingAttributes() {
  return {
    page: AUTH_TOKEN_EXPIRED,
    event: PAGE_LOAD,
    context: AUTH_TOKEN_EXPIRED,
  };
}

export function loginAuthTokenExpiredMagicLinkResendTrackingAttributes() {
  return {
    page: AUTH_TOKEN_EXPIRED,
    event: CLICK,
    context: RESEND_MAGIC_LINK_BUTTON,
  };
}

export function loginSentMagicLinkPageLoadTrackingAttributes() {
  return {
    page: SENT_MAGIC_LINK,
    event: PAGE_LOAD,
    context: SENT_MAGIC_LINK,
  };
}

export function loginSentMagicLinkBackToSignInTrackingAttributes() {
  return {
    page: SENT_MAGIC_LINK,
    event: CLICK,
    context: BACK_TO_SIGN_IN_BUTTON,
  };
}

export function loginSignUpButtonTrackingAttributes(page = LOGIN) {
  return {
    page,
    event: CLICK,
    context: SIGN_UP_BUTTON,
  };
}

export function loginNextButtonTrackingAttributes() {
  return {
    page: LOGIN,
    event: CLICK,
    context: NEXT_BUTTON,
  };
}

export function loginPasswordLetsGoButtonTrackingAttributes() {
  return {
    page: LOGIN,
    event: CLICK,
    context: LETS_GO_BUTTON,
  };
}

export function loginPasswordBackButtonTrackingAttributes() {
  return {
    page: LOGIN,
    event: CLICK,
    context: BACK_BUTTON,
  };
}

export function loginForgotPasswordButtonTrackingAttributes() {
  return {
    page: LOGIN,
    event: CLICK,
    context: FORGOT_PASSWORD_BUTTON,
  };
}

export function createAccountPageLoadTrackingAttributes() {
  return {
    page: CREATE_ACCOUNT,
    event: PAGE_LOAD,
    context: CREATE_ACCOUNT,
  };
}

export function createAccountLoginButtonTrackingAttributes() {
  return {
    page: CREATE_ACCOUNT,
    event: CLICK,
    context: LOGIN_BUTTON,
  };
}

export function createAccountLetsGoButtonTrackingAttributes() {
  return {
    page: CREATE_ACCOUNT,
    event: CLICK,
    context: LETS_GO_BUTTON,
  };
}

export function valuePropPageLoadTrackingAttributes() {
  return {
    page: VALUE_PROPOSITION,
    event: PAGE_LOAD,
    context: VALUE_PROPOSITION,
  };
}

export function valuePropLoginButtonTrackingAttributes(page = 0) {
  return {
    page: `${VALUE_PROPOSITION}_${page}`,
    event: CLICK,
    context: LOGIN_BUTTON,
  };
}

export function valuePropGetStartedButtonTrackingAttributes(page = 0) {
  return {
    page: `${VALUE_PROPOSITION}_${page}`,
    event: CLICK,
    context: GET_STARTED_BUTTON,
  };
}

export function emailConfirmPageLoadTrackingAttributes() {
  return {
    page: EMAIL_CONFIRM_PAGE,
    event: PAGE_LOAD,
    context: EMAIL_CONFIRM_PAGE,
  };
}

export function emailConfirmBackToSignInTrackingAttributes() {
  return {
    page: EMAIL_CONFIRM_PAGE,
    event: CLICK,
    context: BACK_TO_SIGN_IN_BUTTON,
  };
}

export function landingCreateAccountTrackingAttributes() {
  return {
    page: LANDING_CREATE_ACCOUNT,
    event: PAGE_LOAD,
    context: LANDING_CREATE_ACCOUNT,
  };
}

export function landingCreateAccountSendLinkButtonTrackingAttributes() {
  return {
    page: LANDING_CREATE_ACCOUNT,
    event: CLICK,
    context: SEND_MAGIC_LINK_CREATE_ACCOUNT_BUTTON,
  };
}

export function landingPropertyPickerTrackingAttributes() {
  return {
    page: LANDING_PROPERTY_PICKER,
    event: PAGE_LOAD,
    context: LANDING_PROPERTY_PICKER,
  };
}
