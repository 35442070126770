import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { useTracking } from "react-tracking";
import { getOrSetCookie } from "@analytics/utils";

const initialState = {
  event_timestamp: null,
  session_id: null,
  resident_id: null,
  player_id: null,
  user_id: null,
  owner_id: null,
  management_id: null,
  data: {
    hasUser: false,
    email: undefined,
    hasIncentive: false,
  },
  railsContext: {},
};

const AuthenticationContext = createContext(initialState);

const actionTypes = {
  UPDATE_AUTHENTICATION_EMAIL: "UPDATE_AUTHENTICATION_EMAIL",
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_AUTHENTICATION_EMAIL":
      return {
        ...state,
        data: {
          ...state.data,
          email: action.email,
        },
      };
    default:
      return state;
  }
};

function AuthenticationContextProvider(props) {
  const { email, hasUser, hasIncentive, railsContext, playerId, userId, ownerId, management, residentId } = props;

  const resolvedInitialState = {
    ...initialState,
    railsContext,
    player_id: playerId,
    user_id: userId,
    owner_id: ownerId,
    management_id: management?.id,
    resident_id: residentId,
    session_id: getOrSetCookie(),
    data: {
      email,
      hasUser,
      hasIncentive,
    },
  };

  const [state, dispatch] = useReducer(reducer, resolvedInitialState);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { defaultTrackingAttributes: state, dispatch, actionTypes };

  return <AuthenticationContext.Provider value={value}>{props.children}</AuthenticationContext.Provider>;
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  email: PropTypes.string,
  hasUser: PropTypes.bool,
  hasIncentive: PropTypes.bool,
  management: PropTypes.shape({
    id: PropTypes.number,
  }),
  playerId: PropTypes.number,
  userId: PropTypes.number,
  ownerId: PropTypes.number,
  residentId: PropTypes.number,
  railsContext: PropTypes.shape({}).isRequired,
};

AuthenticationContextProvider.defaultProps = {
  email: null,
  hasUser: false,
  hasIncentive: false,
  management: null,
  playerId: null,
  userId: null,
  ownerId: null,
  residentId: null,
};

/**
 * @returns {
 *   @function trackEvent - wrapper around react-tracking to merge default data into
 *   tracking attributes,
 *   @function updateAuthenticationEmail - function to dispatch the action to update context,
 * }
 *
 * The `trackEvent` function needs to account for the default nested `data` attributes & any data
 * attributes passed in from our individual events. So it pulls out the nested `data` object from
 * both and spreads them together properly, default first, event second.
 *
 * defaultData = { data: { defaultData: 'data' }};
 * tracking.trackEvent({ session_id: 1, data: { myData: 'example' }});
 * returns => { session_id: 1, data: { myData: 'example', defaultData: 'data' }}
 */
function useAuthenticationTracking() {
  const tracking = useTracking();
  const { dispatch, actionTypes: actions, defaultTrackingAttributes } = useContext(AuthenticationContext);
  const updateAuthenticationEmail = (email) => dispatch({ type: actions.UPDATE_AUTHENTICATION_EMAIL, email });

  const trackEvent = (trackingAttributes) => {
    const { data: defaultStateData, ...otherDefaultData } = defaultTrackingAttributes;
    const { data: currentTrackingAttributesData, ...currentTrackingAttributes } = trackingAttributes;
    const timeStamp = new Date().toUTCString();
    const allTrackingAttributes = {
      ...otherDefaultData,
      event_timestamp: timeStamp,
      ...currentTrackingAttributes,
      data: {
        ...defaultStateData,
        ...currentTrackingAttributesData,
      },
    };
    tracking.trackEvent(allTrackingAttributes);
  };

  return {
    trackEvent,
    updateAuthenticationEmail,
  };
}

const AuthenticationContextConsumer = AuthenticationContext.Consumer;

export {
  AuthenticationContext,
  AuthenticationContextProvider,
  AuthenticationContextConsumer,
  useAuthenticationTracking,
};
