import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useSpring, animated } from "react-spring";
import { lighten } from "polished";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CR_TOS_URL, CR_PRIVACY_URL } from "@constants/pathConstants";
import { media } from "@utils/style";
import Typography from "@components/atoms/material/MaterialTypography";
import MaterialButton from "@components/atoms/material/MaterialButton";
import Spacing from "@components/layouts/Spacing";
import MaterialIconButton from "@components/atoms/material/MaterialIconButton";
import ArrowDown from "@assets/icons/ArrowDown";
import ArrowRight from "@assets/icons/ArrowRight";
import useWindowSize from "@hooks/useWindowSize";
import { useAuthenticationTracking } from "@contexts/AuthenticationContext";
import {
  organicLandingTrackingAttributes,
  organicLandingGetStartedTrackingAttributes,
  organicLandingLoginButtonTrackingAttributes,
  organicLandingValuePropButtonTrackingAttributes,
} from "@analytics";

const OrganicLandingWrapper = styled("div")`
  position: relative;
  height: 100vh;
  width: 100vw;
  ${media.mobile`
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  `}
`;

const LinksContainerWrapper = styled("div")`
  position: absolute;
  bottom: 2%;
  left: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const CustomLink = styled("a")`
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const BodyWrapper = styled("div")`
  position: relative;
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryElementsWrapper = styled("div")`
  margin-top: -150px;
  max-height: 600px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BottomWrapper = styled("div")`
  position: absolute;
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom) - 16px);
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${(props) => lighten(0.05, props.backgroundColor || "#00A0DF")};
  }
`;

const BackButtonLink = styled("a")`
  position: absolute;
  top: 20;
  left: 10;
`;

const HeaderWrapper = styled("div")`
  z-index: 99;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  padding-top: calc(env(safe-area-inset-top) - 16px);
  width: 100%;
  height: 20px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ExternalLinkTypography = styled(Typography)`
  font-weight: 500;
  padding: 0 10px;
`;

const Logo = styled("img")`
  width: 264px;
  object-fit: contain;
`;

const LogoLink = styled(Link)`
  width: 100%;
`;

const buttonWrapperStyle = { width: "100%" };
const backButtonStyle = { color: "white" };
const getStartedLabel = "GET STARTED";
const createAccount = "Create Account";
const loginLabel = "LOG IN";
const termsText = "Terms";
const privacyText = "Privacy";

function OrganicLanding({ hasUser, programAssets, propertyName, baseUrl }) {
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const tracking = useAuthenticationTracking();
  const { x } = useSpring({ from: { x: 0 }, x: 1, config: { duration: 1000 } });
  const crText = `What is ${programAssets.name}?`;
  const links = useMemo(() => [
    {
      url: CR_TOS_URL,
      text: termsText,
    },
    {
      url: CR_PRIVACY_URL,
      text: privacyText,
    },
  ]);

  useEffect(() => {
    tracking.trackEvent(organicLandingTrackingAttributes());
  }, []);

  const trackGetStartedOnClick = () => {
    tracking.trackEvent(organicLandingGetStartedTrackingAttributes());
  };

  const trackLoginOnClick = () => {
    tracking.trackEvent(organicLandingLoginButtonTrackingAttributes());
  };

  const trackValuePropClick = () => {
    tracking.trackEvent(organicLandingValuePropButtonTrackingAttributes());
  };

  return (
    <OrganicLandingWrapper>
      <HeaderWrapper backgroundColor={theme.palette.secondary.main}>
        <BackButtonLink href={baseUrl || "/"}>
          <MaterialIconButton
            data-testid="back-button-property-picker"
            aria-label="back button"
            style={backButtonStyle}
          >
            <ArrowBackIcon />
          </MaterialIconButton>
        </BackButtonLink>
        <Typography color="contrastPrimary" variant="overline">
          {propertyName}
        </Typography>
      </HeaderWrapper>
      <BodyWrapper>
        <PrimaryElementsWrapper>
          <LogoLink to="/" href="/">
            <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
          </LogoLink>
          <Spacing height="16px" />
          <Link href="/create-account" to="/create-account" style={buttonWrapperStyle}>
            <MaterialButton
              variant="contained"
              onClick={trackGetStartedOnClick}
              color={programAssets.isWhiteLabel ? "white" : "secondary"}
              textColor={programAssets.isWhiteLabel ? "secondary" : "contrastText"}
              type="submit"
              fullWidth
            >
              {hasUser ? createAccount : getStartedLabel}
            </MaterialButton>
          </Link>
          <Spacing height="16px" />
          <Link href="/login" to="/login" style={buttonWrapperStyle}>
            <MaterialButton onClick={trackLoginOnClick} variant="outlined" color="white" fullWidth>
              {loginLabel}
            </MaterialButton>
          </Link>
        </PrimaryElementsWrapper>
        {!isMobile && (
          <LinksContainerWrapper>
            {links.map((link) => (
              <CustomLink key={`${link.text}${link.url}`} href={link.url} rel="noopener noreferrer" target="_blank">
                <ExternalLinkTypography color="contrastPrimary">{link.text}</ExternalLinkTypography>
              </CustomLink>
            ))}
          </LinksContainerWrapper>
        )}
      </BodyWrapper>
      <Link to="/value" href="/value">
        <BottomWrapper backgroundColor={theme.palette.secondary.main} onClick={trackValuePropClick}>
          <Typography color="contrastPrimary">{crText}</Typography>
          <animated.div
            style={{
              marginLeft: "8px",
              opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
              transform: x
                .to({
                  range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                  output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                })
                .to((y) => `scale(${y})`),
            }}
          >
            {isMobile ? (
              <ArrowRight fill={theme.palette.common.white} />
            ) : (
              <ArrowDown fill={theme.palette.common.white} />
            )}
          </animated.div>
        </BottomWrapper>
      </Link>
    </OrganicLandingWrapper>
  );
}

OrganicLanding.propTypes = {
  hasUser: PropTypes.bool,
  propertyName: PropTypes.string.isRequired,
  baseUrl: PropTypes.string,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
  }).isRequired,
};

OrganicLanding.defaultProps = {
  hasUser: false,
  baseUrl: "/",
};

export default OrganicLanding;
