// eslint-disable-next-line import/order
import logRocketStatus from "@utils/logrocketSetup";
import React from "react";
import PropTypes from "prop-types";
import track from "react-tracking";
import setupLogRocketReact from "logrocket-react";
import LogRocket from "logrocket";
import ThemeWrapper from "../../style/ThemeWrapper";
import Authentication from "../features/Authentication";
import ErrorBoundary from "../utility/error/ErrorBoundary";
import CRLogoBlue from "../../assets/images/crLogoBlue.png";
import CRLogo from "../../assets/images/cr-logo-white.png";
import { buildRoutePrefix } from "../../utils/urls";
import { sentryInit, sentryConfigureScope } from "../../utils/sentry";
import { processAuthenticationTrackingData, authenticationAppTrackingAttributes } from "../../analytics";
import { AuthenticationContextProvider } from "../../contexts/AuthenticationContext";
import Router from "../../Router";

@track((props) => authenticationAppTrackingAttributes(props), {
  dispatch: processAuthenticationTrackingData,
  dispatchOnMount: true,
})
class AuthenticationApp extends React.Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === "production" && !window.Cypress) {
      sentryInit(props.railsContext);
      sentryConfigureScope(props.playerId, props.userId, props.email, props.propertyId);

      if (logRocketStatus && !props.mmReferer) {
        setupLogRocketReact(LogRocket);
        if (props.userId) {
          LogRocket.identify(props.userId, {
            email: props.email,
          });
        }
      }
    }
  }

  componentDidMount() {
    if (window?.webkit?.messageHandlers?.rewardsApp && this.props.management.whiteLabelEnabled) {
      const whiteLabelSlug = this.props.whiteLabel?.iosSlug;
      window.webkit.messageHandlers.rewardsApp.postMessage({
        white_label: whiteLabelSlug,
      });
    }
  }

  render() {
    const { railsContext, management, whiteLabel, propertyId, ...otherProps } = this.props;
    const programName = management?.whiteLabelName || "Community Rewards";
    const isWhiteLabel = !!management.whiteLabelEnabled;
    const logoPrimary = isWhiteLabel ? whiteLabel?.primaryLogoUrl : CRLogo;
    const logoSecondary = isWhiteLabel ? whiteLabel?.secondaryLogoUrl : CRLogoBlue;
    const programAssets = {
      name: programName,
      isWhiteLabel,
      logoPrimary,
      logoSecondary,
      propertyId,
    };

    const baseUrl = buildRoutePrefix(railsContext);
    const routePrefix = `${baseUrl}/${otherProps.propertySlug}/auth`;

    return (
      <ThemeWrapper whiteLabel={whiteLabel}>
        <ErrorBoundary inline>
          <AuthenticationContextProvider {...this.props}>
            <Router basename={`${routePrefix}`}>
              <Authentication
                baseUrl={baseUrl}
                routePrefix={routePrefix}
                programAssets={programAssets}
                {...otherProps}
              />
            </Router>
          </AuthenticationContextProvider>
        </ErrorBoundary>
      </ThemeWrapper>
    );
  }
}

AuthenticationApp.propTypes = {
  propertySlug: PropTypes.string.isRequired,
  email: PropTypes.string,
  hasUser: PropTypes.bool,
  hasIncentive: PropTypes.bool,
  railsContext: PropTypes.shape({
    railsEnv: PropTypes.string,
  }).isRequired,
  playerId: PropTypes.number,
  propertyId: PropTypes.number,
  propertyName: PropTypes.string,
  userId: PropTypes.number,
  management: PropTypes.shape({
    productName: PropTypes.string,
    whiteLabelEnabled: PropTypes.bool,
    whiteLabelName: PropTypes.string,
  }).isRequired,
  whiteLabel: PropTypes.shape({
    iosSlug: PropTypes.string,
    primaryLogoUrl: PropTypes.string,
    secondaryLogoUrl: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  mmReferer: PropTypes.bool,
  useCidp: PropTypes.bool,
  cidpUser: PropTypes.bool,
};

AuthenticationApp.defaultProps = {
  email: undefined,
  hasUser: false,
  hasIncentive: false,
  playerId: undefined,
  propertyId: undefined,
  propertyName: undefined,
  userId: undefined,
  whiteLabel: {},
  mmReferer: false,
  useCidp: false,
  cidpUser: false,
};

export default AuthenticationApp;
