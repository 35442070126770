import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useAuthenticationTracking } from "@contexts/AuthenticationContext";
import ConstrictedModal from "../molecules/ConstrictedModal";
import LoginForm from "../molecules/LoginForm";
import AuthHeader from "../molecules/AuthHeader";
import LoginSvg from "../../assets/images/login.svg";
import useWindowSize from "../../hooks/useWindowSize";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import { loginPageLoadTrackingAttributes, loginSignUpButtonTrackingAttributes } from "../../analytics";
import Spacing from "../layouts/Spacing";
import ConditionalWrapper from "../utility/ConditionalWrapper";
import useQuery from "../../hooks/useQuery";

const LoginWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const opacityStyle = { opacity: "0.7" };

export default function Login({ routePrefix, programAssets, hasEmailAuth, useCidp, cidpUser, handleLoginRedirect }) {
  const { isMobile } = useWindowSize();
  const tracking = useAuthenticationTracking();
  const location = useLocation();
  const query = useQuery();
  const currentUserEmail = location?.state?.email || query.get("email");
  const fromPropertyPicker = Boolean(query.get("fromPicker"));

  useEffect(() => {
    tracking.trackEvent(loginPageLoadTrackingAttributes());
  }, []);

  const renderMainText = () => {
    if (isMobile) {
      return (
        <Link to="/" href="/">
          <img src={programAssets.logoPrimary} alt={`${programAssets.name}`} width="100%" />
        </Link>
      );
    }
    return "Welcome Back!";
  };

  const renderSecondaryText = () => {
    if (isMobile) {
      if (fromPropertyPicker) {
        return <a href={window.location.origin}>Sign Up</a>;
      }
      return (
        <Link to="/create-account" href="/create-account">
          Sign Up
        </Link>
      );
    }
    return "";
  };

  const onSignUpClick = () => {
    tracking.trackEvent(loginSignUpButtonTrackingAttributes());
    if (fromPropertyPicker) {
      window.location = window.location.origin;
    }
  };

  return (
    <LoginWrapper>
      {!isMobile && (
        <ConditionalWrapper
          condition={!fromPropertyPicker}
          wrapper={(children) => (
            <Link to="/" href="/">
              {children}
            </Link>
          )}
        >
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </ConditionalWrapper>
      )}

      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <LoginSvg width="100%" height="100%" />
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <AuthHeader mainText={renderMainText()} secondaryText={renderSecondaryText()} />
            <LoginForm
              propertyId={programAssets.propertyId}
              routePrefix={routePrefix}
              currentUserEmail={currentUserEmail}
              hasEmailAuth={hasEmailAuth}
              useCidp={useCidp}
              cidpUser={cidpUser}
              handleLoginRedirect={handleLoginRedirect}
            />
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>

      {!isMobile && (
        <FooterWrapper>
          <Typography variant="caption" color="contrastPrimary" style={opacityStyle}>
            {`Don't have a ${programAssets.name} account?`}
          </Typography>
          <Spacing marginTop="8px" />
          <Link to="/create-account" href="/create-account">
            <MaterialButton
              variant="text"
              color={programAssets.isWhiteLabel ? "white" : "secondary"}
              onClick={onSignUpClick}
            >
              Sign Up
            </MaterialButton>
          </Link>
        </FooterWrapper>
      )}
    </LoginWrapper>
  );
}

Login.propTypes = {
  routePrefix: PropTypes.string.isRequired,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
    propertyId: PropTypes.number,
  }).isRequired,
  hasEmailAuth: PropTypes.bool,
  useCidp: PropTypes.bool,
  cidpUser: PropTypes.bool,
  handleLoginRedirect: PropTypes.func,
};

Login.defaultProps = {
  hasEmailAuth: false,
  useCidp: false,
  cidpUser: false,
  handleLoginRedirect: () => {},
};
