import { defaultTrackingAttributes, globalState } from "./utils";
import { PAGE_LOAD, COMMUNITY_GUIDE } from "./constants";

/**
 * Data to track when <CommunityGuide /> mounts
 */
export function communityGuideTrackingAttributes() {
  return {
    page: COMMUNITY_GUIDE,
    event: PAGE_LOAD,
    context: "CommunityGuide",
    ...defaultTrackingAttributes(),
    data: {
      guideEnabledForCr: globalState().property.guideEnabledForCr,
      welcomeMessage: globalState().property.welcomeMessage,
      staffProfiles: globalState().property.staffProfiles,
      communityGuideItems: globalState().property.communityGuideItems,
    },
  };
}

export default {
  communityGuideTrackingAttributes,
};
