import React, { useState, Fragment, CSSProperties } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Formik, Field, Form } from "formik";
import { string, object, reach } from "yup";
import { Link, useHistory } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { logIn } from "@services/usersService";
import { useAuthenticationTracking } from "@contexts/AuthenticationContext";
import {
  loginAutoLoginLinkButtonTrackingAttributes,
  loginNextButtonTrackingAttributes,
  loginPasswordBackButtonTrackingAttributes,
  loginPasswordLetsGoButtonTrackingAttributes,
} from "@analytics/index";
import MaterialButton from "../atoms/material/MaterialButton";
import Typography from "../atoms/material/MaterialTypography";
import MaterialTextField from "../atoms/material/MaterialTextField";
import Spacing from "../layouts/Spacing";
import InfoBox from "./InfoBox";

const MediumGreyLink = styled(Link)`
  color: ${({ theme }) => theme.palette.mediumGrey.main || "grey"};
  text-decoration: underline !important;
`;

const loginSchema = object({
  email: string().email().required(),
  password: string().required(),
});

const PasswordPage = (props) => {
  const { setCurrentStep, error, history, disableSubmit, tracking } = props;

  const theme = useTheme();

  const goBackOnClick = error.lockedError
    ? () => {
        history.push("/");
        tracking.trackEvent(loginPasswordBackButtonTrackingAttributes());
      }
    : () => {
        setCurrentStep(0);
        tracking.trackEvent(loginPasswordBackButtonTrackingAttributes());
      };
  const goBackText = error.lockedError ? "back to homepage" : "back";

  const trackOnLetsGoClick = () => {
    tracking.trackEvent(loginPasswordLetsGoButtonTrackingAttributes());
  };

  return (
    <Spacing padding="0 24px" width="auto" display="flex" flexDirection="column" alignItems="center">
      <Spacing marginBottom="8px">
        <Typography variant="subtitle2" align="left">
          Password
        </Typography>
      </Spacing>
      <Field name="password">
        {({ field }) => (
          <MaterialTextField
            {...field}
            variant="filled"
            placeholder="Enter Password"
            disabled={Boolean(error.lockedError)}
            label={error.loginError || "Password"}
            error={Boolean(error.loginError)}
            type="password"
            required
            fullWidth
          />
        )}
      </Field>
      <Spacing height="16" />
      <Spacing>
        <Typography variant="caption" align="right">
          <MediumGreyLink
            theme={theme}
            to={{
              pathname: "/password-reset",
              state: { email: props.values.email },
            }}
            href="/password-reset"
          >
            Forgot Password?
          </MediumGreyLink>
        </Typography>
      </Spacing>
      <Spacing height="16" />
      {!error.lockedError && (
        <MaterialButton
          onClick={trackOnLetsGoClick}
          variant="contained"
          color="secondary"
          type="submit"
          disabled={disableSubmit}
          fullWidth
        >
          Let&#39;s Go!
        </MaterialButton>
      )}
      <InfoBox color="alert" show={Boolean(error.lockedError)}>
        <InfoBox.Heading>That is a lot of incorrect attempts. </InfoBox.Heading>
        <InfoBox.Body>
          <ul>
            <li>For security purposes, we have locked this login for 20 minutes.</li>
            <li>
              If you have forgotten your password feel free to
              <Link
                to={{
                  pathname: "/password-reset",
                  state: { email: props.values.email },
                }}
                href="/password-reset"
              >
                reset your password.
              </Link>
            </li>
          </ul>
        </InfoBox.Body>
      </InfoBox>
      <Spacing height="16" />
      <MaterialButton
        onClick={goBackOnClick}
        variant="text"
        color="secondary"
        type="button"
        disabled={disableSubmit}
        fullWidth
        data-testid="back-button"
      >
        {goBackText}
      </MaterialButton>
    </Spacing>
  );
};

PasswordPage.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  error: PropTypes.shape({
    lockedError: PropTypes.string,
    loginError: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  tracking: PropTypes.shape({}).isRequired,
};

const EmailPage = (props) => {
  const {
    values,
    validateField,
    validateEmail,
    currentUserEmail,
    setCurrentStep,
    tracking,
    setErrors,
    useCidp,
    cidpUser,
    handleLoginRedirect,
  } = props;
  const { updateAuthenticationEmail, trackEvent } = tracking;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const ssoAtNonCIDPProperty = !useCidp && cidpUser;

  const onNextButtonClick = () => {
    validateField("email")
      .then((errorMsg: string) => {
        if (errorMsg === undefined) {
          setCurrentStep(1);
          updateAuthenticationEmail(values.email);
          trackEvent(loginNextButtonTrackingAttributes());
        }
      })
      .catch((error: string) => {
        console.log(error); // eslint-disable-line no-console
      });
  };

  const onComplete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoading(true);
    validateField("email")
      .then((errorMsg: string) => {
        if (errorMsg === undefined) {
          handleLoginRedirect(values.email, ssoAtNonCIDPProperty);
          trackEvent(loginAutoLoginLinkButtonTrackingAttributes());
        }
        setLoading(false);
      })
      .catch((error: string) => {
        console.log(error); // eslint-disable-line no-console
        setErrors({ email: "Sorry, something went wrong" });
        setLoading(false);
      });
  };

  const regularButtons = (
    <Fragment>
      {ssoAtNonCIDPProperty ? (
        <>
          <Typography variant="caption">or</Typography>
          <Spacing height="16" />
        </>
      ) : (
        <Spacing margin="16px 0">
          <Typography variant="body2" align="center">
            Long password? Hard to type? <br /> Get a link to login instantly
          </Typography>
        </Spacing>
      )}
      <MaterialButton
        disabled={values.email.length === 0 || loading}
        onClick={onComplete}
        variant={ssoAtNonCIDPProperty ? "outlined" : "contained"}
        color="secondary"
        type="button"
        fullWidth
      >
        Send Login Link
      </MaterialButton>
      <Spacing height="8" />
      <Typography variant="caption">or</Typography>
      <Spacing height="8" />
      <MaterialButton
        data-testid="passwordButton"
        disabled={values.email.length === 0}
        onClick={onNextButtonClick}
        variant="text"
        color="secondary"
        type="button"
        fullWidth
      >
        Continue with Password
      </MaterialButton>
    </Fragment>
  );

  return (
    <Spacing padding="24px" width="auto" height="65%" display="flex" flexDirection="column" alignItems="center">
      <Spacing marginBottom="8px">
        <Typography variant="subtitle2" align="left">
          {useCidp ? "Your Email" : "Login"}
        </Typography>
      </Spacing>
      <Field name="email" validate={validateEmail}>
        {({ field, form: { errors } }) => (
          <MaterialTextField
            {...field}
            disabled={!!currentUserEmail}
            variant="filled"
            label={errors && errors[field.name] ? errors[field.name] : "Email Address"}
            placeholder="Email Address"
            type="email"
            error={!!errors[field.name]}
            required
            fullWidth
          />
        )}
      </Field>

      {currentUserEmail && (
        <Fragment>
          <Spacing height="16" />
          <Typography variant="caption" align="right" style={{ width: "100%" }}>
            <MediumGreyLink style={{ alignSelf: "flex-end" }} theme={theme} to="/" href="/">
              Not You?
            </MediumGreyLink>
          </Typography>
        </Fragment>
      )}

      {(useCidp || cidpUser) && (
        <Spacing margin="16px 0" style={{ textAlign: "center" }}>
          <MaterialButton
            disabled={values.email.length === 0 || loading}
            onClick={onComplete}
            variant="contained"
            color="secondary"
            type="button"
            fullWidth
          >
            {ssoAtNonCIDPProperty ? "Single Sign On" : "Continue"}
          </MaterialButton>
        </Spacing>
      )}

      {!useCidp && regularButtons}
    </Spacing>
  );
};

EmailPage.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  currentUserEmail: PropTypes.string,
  tracking: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  propertyId: PropTypes.number.isRequired,
  setErrors: PropTypes.func.isRequired,
  useCidp: PropTypes.bool,
  cidpUser: PropTypes.bool,
  handleLoginRedirect: PropTypes.func,
};

EmailPage.defaultProps = {
  currentUserEmail: undefined,
  useCidp: false,
  cidpUser: false,
  handleLoginRedirect: () => {},
};

const pages = [EmailPage, PasswordPage];

const formStyle: React.CSSProperties = {
  minHeight: "240px",
  minWidth: "320px",
  maxWidth: "660px",
  overflow: "hidden",
  position: "relative",
};

const transitionOffset = 125;

interface LoginErrorState {
  loginError?: string;
  lockedError?: string;
}

const initialErrorState: LoginErrorState = { loginError: undefined, lockedError: undefined };

interface LoginFormProps {
  routePrefix: Location;
  currentUserEmail?: string;
  hasEmailAuth?: boolean;
  propertyId: number;
  useCidp?: boolean;
  cidpUser?: boolean;
  handleLoginRedirect: () => void;
}

function LoginForm(props: LoginFormProps) {
  const { routePrefix, currentUserEmail, hasEmailAuth, propertyId, useCidp, cidpUser, handleLoginRedirect } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState(initialErrorState);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const history = useHistory();
  const tracking = useAuthenticationTracking();

  const currentEmail = currentUserEmail || "";
  const initialFormValues = { email: currentEmail, password: "" };

  const transitions = useTransition(pages[currentStep], {
    initial: { opacity: 1 },
    from: (item) => {
      const direction = item === EmailPage ? "-" : "";
      return { opacity: 0, transform: `translate3d(${direction}${transitionOffset}%,0,0)` };
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: (item) => {
      const direction = item === EmailPage ? "-" : "";
      return {
        opacity: 0,
        transform: `translate3d(${direction}${transitionOffset}%,0,0)`,
        position: "absolute",
        minWidth: "320px",
        maxWidth: "660px",
        width: "100%",
      };
    },
  });

  function validateEmail(value) {
    try {
      reach(loginSchema, "email").validateSync(value);
      setError(initialErrorState);
      return undefined;
    } catch (e) {
      return e.message;
    }
  }

  const parseErrors = (e, params) => {
    setDisableSubmit(false);
    if (e?.error?.includes?.("Your account is locked.")) {
      setError({ lockedError: e.error });
      return;
    }
    if (e?.error?.includes?.("one")) {
      setError({ loginError: "1 more attempt available" });
      return;
    }
    if (e?.error?.includes("confirm")) {
      history.push("/email-confirm", { email: params.email });
    }

    setError({ loginError: e?.error });
  };

  const onSubmit = (params) => {
    setDisableSubmit(true);
    logIn(params)
      .then(() => {
        window.location = routePrefix;
      })
      .catch((e) => parseErrors(e, params));
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={false}>
      {(form) => (
        <Form noValidate style={formStyle as CSSProperties}>
          {transitions((sprops, item) => {
            const Page = item;
            const componentProps = {
              ...form,
              validateEmail,
              setCurrentStep,
              error,
              history,
              disableSubmit,
              tracking,
              currentUserEmail,
              hasEmailAuth,
              propertyId,
              useCidp,
              cidpUser,
              handleLoginRedirect,
            };
            return (
              <animated.div style={sprops}>
                <Page {...componentProps} />
              </animated.div>
            );
          })}
        </Form>
      )}
    </Formik>
  );
}

LoginForm.defaultProps = {
  currentUserEmail: undefined,
  hasEmailAuth: false,
  useCidp: false,
  cidpUser: false,
};

export default LoginForm;
