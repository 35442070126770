import React, { useMemo } from "react";
import PropTypes from "prop-types";
import WindowedSelect, { createFilter, components } from "react-windowed-select";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/material/styles";
import NoSsr from "@mui/material/NoSsr";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "../atoms/material/MaterialTypography";
import { zIndexAutoComplete } from "../../constants/zIndexConstants";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: "0 0 0 16px",
    height: "48px",
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "4px",
  },
  valueContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  placeholder: {
    position: "absolute",
    paddingLeft: "3px",
  },
  singleValue: {
    paddingLeft: "0px",
  },
  paper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    zIndex: zIndexAutoComplete /* TODO: Determine why this is still under overlay HOC */,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      noWrap
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      noOptionsMessage: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

NoOptionsMessage.defaultProps = {
  children: null,
  innerProps: {},
};

const InputDiv = styled("div")`
  /* stylelint-disable no-empty-block */
`;

const inputComponent = React.forwardRef((props, ref) => <InputDiv {...props} ref={ref} />);

inputComponent.propTypes = {
  children: PropTypes.node,
  inputRef: PropTypes.func,
  innerProps: PropTypes.shape({}),
};

inputComponent.defaultProps = {
  children: null,
  inputRef: null,
  innerProps: {},
};

function Control(props) {
  return (
    <TextField
      fullWidth
      classes={{
        root: props.selectProps.classes.inputRoot,
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          style: { height: props.selectProps.height },
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    height: PropTypes.string,
    textFieldProps: PropTypes.shape({}),
    classes: PropTypes.shape({
      input: PropTypes.string,
      inputRoot: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Control.defaultProps = {
  children: null,
  innerRef: null,
  innerProps: {},
};

const Option = React.memo((props) => {
  // Don't pass unnecessary functions to each option
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        whiteSpace: "normal",
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...newInnerProps}
    >
      <Typography variant="body2">{props.children}</Typography>
    </MenuItem>
  );
});

Option.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  innerProps: PropTypes.shape({
    onMouseMove: PropTypes.func,
    onMouseOver: PropTypes.func,
  }),
  innerRef: PropTypes.func,
};

Option.defaultProps = {
  children: null,
  isSelected: false,
  isFocused: false,
  innerRef: null,
  innerProps: {
    onMouseMove: () => {},
    onMouseOver: () => {},
  },
};

function Placeholder(props) {
  return (
    <Typography noWrap color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      placeholder: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Placeholder.defaultProps = {
  children: null,
  innerProps: {},
};

function SingleValue(props) {
  return (
    <Typography noWrap className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      singleValue: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

SingleValue.defaultProps = {
  children: null,
  innerProps: {},
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      valueContainer: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

ValueContainer.defaultProps = {
  children: null,
};

const Menu = React.memo((props) => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
));

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      paper: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Menu.defaultProps = {
  children: null,
  innerProps: {},
};

const customFilter = createFilter({ ignoreAccents: false });

const AutoComplete = (props) => {
  const {
    showDropdownIndicator,
    showClearIndicator,
    showIndicatorSeparator,
    classes,
    theme,
    options,
    value,
    onChange,
    placeholder,
    customStyles,
    ...otherProps
  } = props;

  const selectStyles = useMemo(
    () => ({
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
      option: (base) => ({
        ...base,
        height: 48,
      }),
      ...customStyles,
    }),
    [theme, customStyles]
  );

  const customComponents = useMemo(
    () => ({
      Control,
      Menu,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      ClearIndicator: showClearIndicator && components.ClearIndicator,
      DropdownIndicator: showDropdownIndicator && components.DropdownIndicator,
      IndicatorSeparator: showIndicatorSeparator && components.IndicatorSeparator,
    }),
    []
  );

  return (
    <div className={classes.root}>
      <NoSsr>
        <WindowedSelect
          classes={classes}
          styles={selectStyles}
          components={customComponents}
          filterOption={customFilter}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          options={options}
          {...otherProps}
        />
      </NoSsr>
    </div>
  );
};

AutoComplete.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      text: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
  }).isRequired,
  showClearIndicator: PropTypes.bool,
  showDropdownIndicator: PropTypes.bool,
  showIndicatorSeparator: PropTypes.bool,
  height: PropTypes.string,
  customStyles: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

AutoComplete.defaultProps = {
  showClearIndicator: false,
  showDropdownIndicator: false,
  showIndicatorSeparator: false,
  height: "48px",
  options: [],
  customStyles: {},
  value: undefined,
  onChange: () => {},
  placeholder: "",
};

export default React.memo(withStyles(styles, { withTheme: true })(AutoComplete));
