import camelCaseKeys from "camelcase-keys";
import ajaxCall from "./ajaxCall";

export async function logOut(redirect = "/users/sign_in") {
  ajaxCall({
    url: "/users/sign_out",
    method: "DELETE",
  }).then(() => {
    window.location.href = redirect;
  });
}

/**
 * User Login Service
 * @param {Object} params - Body of post request
 * @param {String} params.email - User email
 * @param {String} params.password - User password
 */

export async function logIn(params) {
  const body = { user: params };
  const response = ajaxCall({
    url: "/users/sign_in.json",
    method: "POST",
    body,
  }).then((res) => camelCaseKeys(res, { deep: true }));

  return response;
}

/**
 * Password Reset Service
 * @param {Object} params - Body of put request
 * @param {String} params.reset_password_token - Password Reset Token
 * @param {String} params.password - New Password
 * @param {String} params.password_confirmation - New password confirmation
 */

export async function resetPassword(params) {
  const body = { user: params };
  const response = ajaxCall({
    url: "/users/password.json",
    method: "PUT",
    body,
  });

  return response;
}

/**
 * Send Password Reset Service
 * @param {Object} params - Body of post request
 * @param {String} params.email - Entered email
 */

export async function sendResetPassword(params) {
  const body = { user: params };
  const response = ajaxCall({
    url: "/users/password.json",
    method: "POST",
    body,
  }).then((res) => camelCaseKeys(res, { deep: true }));

  return response;
}

/**
 * Create Account Service
 * @param {Object} params - User Data
 * @param {String} params.email - Entered email
 * @param {String} params.password - Entered password
 */
export async function createAccount(params) {
  const { email, password, confirmPassword, tosAccepted } = params;
  const body = {
    user: {
      email,
      password,
      password_confirmation: confirmPassword,
      tos_accepted: tosAccepted,
    },
  };

  const response = ajaxCall({
    url: "/users.json",
    method: "POST",
    body,
  }).then((res) => camelCaseKeys(res, { deep: true }));

  return response;
}

/**
 * Resend Confirmation Email
 * @param {Object} params - User Data
 * @param {String} params.email - Entered email
 */
export async function resendConfirmationEmail(params) {
  const { email } = params;
  const body = {
    user: {
      email,
    },
  };

  const response = ajaxCall({
    url: "/users/confirmation.json",
    method: "POST",
    body,
  }).then((res) => camelCaseKeys(res, { deep: true }));

  return response;
}

/**
 * Send Magic Link
 */
export async function sendMagicLink({ email, propertyId, enrollCIDP }) {
  const body = { email, property_id: propertyId, enroll_cidp: enrollCIDP };
  const response = await ajaxCall({
    url: "/api/auto_login/create",
    method: "POST",
    body,
  });

  return response;
}

/**
 * Send Magic Link
 */
export async function sendOrganicMagicLink(params) {
  const response = await ajaxCall({
    url: "/api/auto_login/organic_create",
    method: "POST",
    body: params,
  });

  return response;
}

export async function sendInvitationMagicLink(params) {
  const response = await ajaxCall({
    url: "/api/auto_login/invitation_create",
    method: "POST",
    body: params,
  });

  return response;
}
