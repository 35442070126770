import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";
import ConditionalWrapper from "../utility/ConditionalWrapper";
import useWindowSize from "../../hooks/useWindowSize";
import { DEVICE_SIZES } from "../../constants/styleConstants";

const breakPoint = `${DEVICE_SIZES.mobile}px`;

const ModalContainer = styled("div")`
  display: inline-flex;
  height: 100%;
  width: 100%;
`;

const ModalLeft = styled("div")`
  width: 50%;
  height: 100%;
  @media (max-width: ${breakPoint}) {
    display: none;
  }
`;

const ModalRight = styled("div")`
  width: 50%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  @media (max-width: ${breakPoint}) {
    width: 100%;
  }
`;

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 16px 16px -8px rgba(13,66,90,0.10)",
    margin: "0px",
    width: "640px",
    height: "576px",
    [`@media (max-height:${breakPoint})`]: {
      minHeight: "576px",
    },
  },
  paperScrollPaper: {
    maxHeight: "100%",
  },
  paperWidthSm: {
    maxWidth: "unset",
  },
  paperMobile: {
    margin: "0px",
    width: "100vw",
    height: "100vh",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 99,
  },
});

const ConstrictedModal = (props) => {
  const { onClose, open, classes, children, backdropInvisible, closeButtonVisible, ariaLabeledBy, isModal } = props;

  const { isMobile } = useWindowSize();
  const mobileClass = isMobile ? classes.paperMobile : classes.paper;
  const paperScrollPaper = isMobile ? classes.paperScrollPaper : undefined;
  const { paperWidthSm } = classes;

  return (
    <ConditionalWrapper
      condition={isModal}
      wrapper={(modalChildren) => (
        <Dialog
          classes={{ paper: mobileClass, paperScrollPaper, paperWidthSm }}
          onClose={onClose}
          aria-labelledby={ariaLabeledBy}
          open={open}
          BackdropProps={{ invisible: backdropInvisible }}
        >
          {closeButtonVisible && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          )}
          {modalChildren}
        </Dialog>
      )}
    >
      <Paper className={mobileClass}>{children}</Paper>
    </ConditionalWrapper>
  );
};

ConstrictedModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.shape({
    paperMobile: PropTypes.string,
    paperScrollPaper: PropTypes.string,
    paperWidthSm: PropTypes.string,
    closeButton: PropTypes.string,
    paper: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  backdropInvisible: PropTypes.bool,
  closeButtonVisible: PropTypes.bool,
  ariaLabeledBy: PropTypes.string,
  isModal: PropTypes.bool,
};

ConstrictedModal.defaultProps = {
  classes: {},
  open: false,
  onClose: () => {},
  children: null,
  backdropInvisible: false,
  closeButtonVisible: true,
  ariaLabeledBy: "modal",
  isModal: false,
};

ConstrictedModal.ModalContainer = ModalContainer;
ConstrictedModal.ModalLeft = ModalLeft;
ConstrictedModal.ModalRight = ModalRight;

export default withStyles(styles)(ConstrictedModal);
