import { defaultTrackingAttributes, globalState } from "./utils";
import { CLICK, VIEW, TAB_BAR } from "./constants";

export function earnTabViewTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: VIEW,
    context: "EarnTabView",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function redeemTabViewTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: VIEW,
    context: "RedeemTabView",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function benefitsTabViewTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: VIEW,
    context: "BenefitsTabView",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function activityTabViewTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: VIEW,
    context: "ActivityTabView",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function earnTabClickTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: CLICK,
    context: "EarnTabClick",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function redeemTabClickTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: CLICK,
    context: "RedeemTabClick",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function benefitsTabClickTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: CLICK,
    context: "BenefitsTabClick",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}

export function activityTabClickTrackingAttributes() {
  return {
    page: TAB_BAR,
    event: CLICK,
    context: "ActivityTabClick",
    ...defaultTrackingAttributes(),
    data: {
      propertyId: globalState().property.id,
      managementId: globalState().management.id,
    },
  };
}
