import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import AuthBackground from "../../../assets/images/authBackground.svg";
import { zIndexNewAuthBackground } from "../../../constants/zIndexConstants";

const isIOSWebViewApp = window?.webkit?.messageHandlers?.rewardsApp;

function bgStarColor(prim, sec) {
  // if primary and secondary are the same use default for star color
  if (prim === sec) {
    return "#002856";
  }
  return prim;
}

const AuthWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: ${isIOSWebViewApp ? "100vh" : "100%"};
  .background {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .behind {
    position: fixed;
    z-index: ${zIndexNewAuthBackground};
  }
  .background #start {
    stop-color: ${(props) => props.secondary} !important;
  }
  .background #end {
    stop-color: ${(props) => props.primary} !important;
  }
  .background .bg_star {
    fill: ${(props) => bgStarColor(props.primary, props.secondary)} !important;
  }
  a {
    text-decoration: none;
  }
`;

const AuthWrapperBackground = (props) => {
  const theme = useTheme();

  return (
    <AuthWrapper primary={theme.palette.primary.main} secondary={theme.palette.secondary.main}>
      <AuthBackground className="background behind" />
      {props.children}
    </AuthWrapper>
  );
};

AuthWrapperBackground.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AuthWrapperBackground.defaultProps = {
  children: null,
};

export default AuthWrapperBackground;
