import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import Button from "../../atoms/material/MaterialButton";
import Typography from "../../atoms/material/MaterialTypography";
import SomethingBroke from "../../../assets/images/somethingBroke.svg";

const ErrorInlineWrapper = styled("div")`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const buttonStyle = { margin: "1rem" };

export default function ErrorInline({ renderFeedback, disableContainer, eventId, showSVG }) {
  const cleanUp = () => {
    document.getElementById("container-fluid").style.display = "";
  };

  useEffect(() => {
    if (disableContainer) {
      document.getElementById("container-fluid").style.display = "none";
      return cleanUp;
    }
    return undefined;
  }, []);

  const showReportDialog = () => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.showReportDialog({ eventId });
    } else {
      // eslint-disable-next-line no-alert
      alert("Development Error: No Sentry request made");
    }
  };

  return (
    <ErrorInlineWrapper>
      {showSVG && <SomethingBroke />}
      <Typography variant="h6">Something broke :(</Typography>
      <Typography variant="body2">Refresh your page or try again later.</Typography>
      <Button onClick={() => window.location.reload()} style={buttonStyle} variant="contained" color="alert">
        Refresh
      </Button>
      {renderFeedback && (
        <Button onClick={showReportDialog} style={buttonStyle} variant="contained" color="secondary">
          Report your error
        </Button>
      )}
    </ErrorInlineWrapper>
  );
}

ErrorInline.propTypes = {
  renderFeedback: PropTypes.bool,
  disableContainer: PropTypes.bool,
  showSVG: PropTypes.bool,
  eventId: PropTypes.string,
};

ErrorInline.defaultProps = {
  renderFeedback: true,
  showSVG: true,
  disableContainer: false,
  eventId: undefined,
};
