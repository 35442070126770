import { styled } from "@mui/material/styles";
import { boxModel, flexModel } from "@utils/style";

function formatSize(size, fallback) {
  if (
    typeof size === "string" &&
    (size.includes("em") || size.includes("px") || size.includes("%") || size.includes("auto"))
  ) {
    return size;
  }
  if (typeof size === "number" || (typeof size === "string" && !size.includes("px"))) {
    return `${size}px`;
  }
  return fallback || size;
}

const Spacing = styled("div")`
  height: ${(props) => formatSize(props.height, "auto")};
  width: ${(props) => formatSize(props.width, "100%")};
  content: "";
  ${boxModel}
  ${flexModel}
`;

export default Spacing;
