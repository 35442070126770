import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MaterialIconButton from "../atoms/material/MaterialIconButton";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import ConstrictedModal from "../molecules/ConstrictedModal";
import Img from "../atoms/Img";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import LinkSentMobile from "../../assets/images/LinkSentMobile.png";
import LinkSentDesktop from "../../assets/images/LinkSentDesktop.png";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import { SENT_MAGIC_LINK } from "../../analytics/constants";
import {
  loginSentMagicLinkPageLoadTrackingAttributes,
  loginSentMagicLinkBackToSignInTrackingAttributes,
  loginSignUpButtonTrackingAttributes,
} from "../../analytics";

const CenterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const BoldUnderline = styled("span")`
  font-weight: bold;
  text-decoration: underline;
  overflow-wrap: break-word;
`;

const backButtonStyle = { position: "absolute", top: "16px", left: "16px" };

const SentMagicLink = ({ programAssets }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  let email = history?.location?.state?.email;
  const tracking = useAuthenticationTracking();

  if (!email) {
    const params = new URLSearchParams(history.location.search);
    email = params.get("email");
  }

  const trackSignUpClick = () => {
    tracking.trackEvent(loginSignUpButtonTrackingAttributes(SENT_MAGIC_LINK));
  };
  const trackOnBackToSignIn = () => {
    tracking.trackEvent(loginSentMagicLinkBackToSignInTrackingAttributes());
  };

  useEffect(() => {
    tracking.trackEvent(loginSentMagicLinkPageLoadTrackingAttributes());
  }, []);

  return (
    <CenterWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </Link>
      )}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={LinkSentDesktop} width="100%" alt="Link Sent!" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing padding="24px" width="auto" display="flex" flexDirection="column">
              {isMobile ? (
                <Spacing width="auto" display="flex" flexDirection="column" justifyContent="center" height="220">
                  <Link to="/login" href="/login">
                    <MaterialIconButton
                      data-testid="back-button-link-sent"
                      aria-label="back button"
                      style={backButtonStyle}
                      onClick={trackOnBackToSignIn}
                    >
                      <ArrowBackIcon />
                    </MaterialIconButton>
                  </Link>
                  <Img alt="Link Sent!" src={LinkSentMobile} />
                </Spacing>
              ) : (
                <Spacing height={120} />
              )}
              <Typography variant="h6" align="center">
                Check your email!
              </Typography>
              <Spacing height="24" />
              <Typography variant="body2" align="center">
                We&apos;ve sent a message to <br />
                <br />
                <BoldUnderline>{email}</BoldUnderline>
                <br />
                <br />
                with a link to login.
              </Typography>
            </Spacing>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && (
        <FooterWrapper>
          <Typography variant="caption" color="contrastPrimary">
            {`Dont have a ${programAssets.name} account?`}
          </Typography>
          <Spacing marginTop="8px" />
          <Link to="/create-account" href="/create-account">
            <MaterialButton
              variant="text"
              color={programAssets.isWhiteLabel ? "white" : "secondary"}
              onClick={trackSignUpClick}
            >
              Sign Up
            </MaterialButton>
          </Link>
        </FooterWrapper>
      )}
    </CenterWrapper>
  );
};

SentMagicLink.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
  }).isRequired,
};

export default SentMagicLink;
