import * as Sentry from "@sentry/browser";

export function sentryInit(railsContext) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: railsContext.railsEnv,
    release: window.RELEASE || "development",
    autoSessionTracking: false,
    sendDefaultPii: true,
    beforeSend(event, hint) {
      const { originalException } = hint;
      if (
        (originalException &&
          (originalException.name === "UnhandledRejection" ||
            originalException.name === "" ||
            originalException.name === null)) ||
        originalException === "" ||
        originalException === null
      ) {
        return null;
      }

      // see https://github.com/getsentry/sentry-javascript/issues/3440
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event?.exception !== undefined &&
        event.exception?.values !== undefined &&
        event.exception.values?.length === 1
      ) {
        const e = event.exception.values[0];
        const objectNotFoundRegExp = new RegExp(
          "Non-Error promise rejection captured with value: Object Not Found Matching Id:*",
          "gi"
        );

        if (e.type === "UnhandledRejection" && objectNotFoundRegExp.test(e.value)) {
          return null;
        }
      }

      return event;
    },
  });
}

export function sentryConfigureScope(playerId, userId, email, propertyId) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      playerId,
      userId,
      email,
      propertyId,
    });
  });
}
