import { defaultTrackingAttributes } from "./utils";
import { PAGE_LOAD, MAIN_APP } from "./constants";

/**
 * Data to track when <MainApp /> mounts
 */
export function mainAppTrackingAttributes() {
  return {
    page: MAIN_APP,
    event: PAGE_LOAD,
    context: "MainApp",
    perksEnabled: true,
    ...defaultTrackingAttributes(),
  };
}

export default {
  mainAppTrackingAttributes,
};
