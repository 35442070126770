import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ConstrictedModal from "../molecules/ConstrictedModal";
import NewCreateAccountForm from "../molecules/NewCreateAccountForm";
import AuthHeader from "../molecules/AuthHeader";
import GetStartedCreateAccount from "../../assets/images/GetStartedCreateAccount.png";
import useWindowSize from "../../hooks/useWindowSize";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import Spacing from "../layouts/Spacing";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import { createAccountPageLoadTrackingAttributes, createAccountLoginButtonTrackingAttributes } from "../../analytics";

const CreateAccountWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const opacityStyle = { opacity: "0.7" };

export default function NewCreateAccount({
  email,
  hasUser,
  playerId,
  routePrefix,
  programAssets,
  useCidp,
  handleLoginRedirect,
}) {
  const { isMobile } = useWindowSize();

  const tracking = useAuthenticationTracking();

  useEffect(() => {
    tracking.trackEvent(createAccountPageLoadTrackingAttributes());
  }, []);

  const renderSecondaryText = () => {
    if (isMobile) {
      return (
        <Link to="/login" href="/login">
          Log in
        </Link>
      );
    }
    return "";
  };

  const trackLoginButtonClick = () => {
    tracking.trackEvent(createAccountLoginButtonTrackingAttributes());
  };

  return (
    <CreateAccountWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </Link>
      )}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={GetStartedCreateAccount} width="100%" alt="get started create an account" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <AuthHeader mainText="Create Account" secondaryText={renderSecondaryText()} />
            <NewCreateAccountForm
              playerId={playerId}
              email={email}
              hasUser={hasUser}
              routePrefix={routePrefix}
              useCidp={useCidp}
              handleLoginRedirect={handleLoginRedirect}
            />
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && (
        <FooterWrapper>
          <Typography variant="caption" color="contrastPrimary" style={opacityStyle}>
            {`Already have a ${programAssets.name} account?`}
          </Typography>
          <Spacing marginTop="8px" />
          <Link to="/login" href="/login">
            <MaterialButton
              onClick={trackLoginButtonClick}
              variant="text"
              color={programAssets.isWhiteLabel ? "white" : "secondary"}
            >
              Login
            </MaterialButton>
          </Link>
        </FooterWrapper>
      )}
    </CreateAccountWrapper>
  );
}

NewCreateAccount.propTypes = {
  email: PropTypes.string,
  hasUser: PropTypes.bool,
  routePrefix: PropTypes.string.isRequired,
  playerId: PropTypes.number,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
  }).isRequired,
  useCidp: PropTypes.bool,
  handleLoginRedirect: PropTypes.func.isRequired,
};

NewCreateAccount.defaultProps = {
  email: undefined,
  hasUser: false,
  playerId: null,
  useCidp: false,
};
