import React from "react";
import { styled } from "@mui/material/styles";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import NewUserRegistration from "@components/features/NewUserRegistration";
import ValueProposition from "@components/features/ValueProposition";
import PropertySelect from "@components/features/PropertySelect";
import SentOrganicMagicLink from "@components/features/SentOrganicMagicLink";
import usePrevious from "@hooks/usePrevious";
import useWindowSize from "@hooks/useWindowSize";
import { media } from "@utils/style";

const CenterWrapper = styled("div")`
  display: flex;
  justify-content: center;
  height: 100%;
  ${media.mobile`
    height: unset;
  `}
`;

const transitionOffset = 100;

function getDirection(newPath, lastPath, isMobile) {
  const toRoot = newPath === "/";
  const fromRoot = lastPath === "/";

  if (isMobile) {
    switch (true) {
      case toRoot:
        return "left";
      case !toRoot:
        return "right";
      default:
        return "right";
    }
  } else {
    switch (true) {
      case newPath === "/value" && fromRoot:
      case !toRoot && !fromRoot && newPath !== "/value" && lastPath !== "/value":
        return "down";
      case lastPath === "/value" && toRoot:
        return "up";
      case toRoot && lastPath !== "/value":
        return "left";
      case !toRoot && newPath !== "/value":
        return "right";
      default:
        return "right";
    }
  }
}

function createTransform(transition, newPath, lastPath, isMobile) {
  const from = transition === "from";
  const direction = getDirection(newPath, lastPath, isMobile);
  switch (direction) {
    case "up":
      return `translate3d(0, ${from ? "-" : ""}${transitionOffset}%, 0)`;
    case "down":
      return `translate3d(0, ${from ? "" : "-"}${transitionOffset}%, 0)`;
    case "left":
      return `translate3d(${from ? "-" : ""}${transitionOffset}%, 0,0)`;
    case "right":
      return `translate3d(${from ? "" : "-"}${transitionOffset}%, 0,0)`;
    default:
      return `translate3d(${from ? "" : "-"}${transitionOffset}%, 0,0)`;
  }
}

function PropertySelectRouter(props) {
  const location = useLocation();
  const lastLocation = usePrevious(location) || { pathname: "/" };
  const { isMobile } = useWindowSize();
  const transitions = useTransition(location, {
    initial: { opacity: 0 },
    from: {
      opacity: 0,
      transform: createTransform("from", location.pathname, lastLocation.pathname, isMobile),
      position: "absolute",
      overflow: "hidden",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      width: "100%",
      height: "100%",
    },
    leave: {
      opacity: 0,
      transform: createTransform("leave", location.pathname, lastLocation.pathname, isMobile),
      position: "absolute",
      overflow: "hidden",
    },
  });

  return transitions((propstransitionStyle, item) => (
    <animated.div style={propstransitionStyle}>
      <Switch location={item}>
        <Route path="/" exact>
          <NewUserRegistration {...props} /> :
        </Route>
        <Route path="/value">
          <ValueProposition {...props} noProperty />
        </Route>
        <Route path="/link-sent">
          <CenterWrapper>
            <SentOrganicMagicLink />
          </CenterWrapper>
        </Route>
        <Route path="/property-select">
          <CenterWrapper>
            <PropertySelect {...props} />
          </CenterWrapper>
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </animated.div>
  ));
}

export default PropertySelectRouter;
