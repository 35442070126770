import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { media } from "../../utils/style";
import Typography from "../atoms/material/MaterialTypography";
import PageSlides from "../layouts/PageSlides";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import MaterialButton from "../atoms/material/MaterialButton";
import parkBenchImage from "../../assets/images/ValPropParkBench.png";
import errandsImage from "../../assets/images/ValPropErrands.png";
import workplaceImage from "../../assets/images/ValPropWorkplace.png";
import magGlassImage from "../../assets/images/ValPropMagGlass.png";
import cipImage from "../../assets/images/ValPropCIP.png";
import { zIndexValuePropositionHeader } from "../../constants/zIndexConstants";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import {
  valuePropPageLoadTrackingAttributes,
  valuePropLoginButtonTrackingAttributes,
  valuePropGetStartedButtonTrackingAttributes,
} from "../../analytics";

const ValuePropositionWrapper = styled("div")`
  position: relative;
  background: white;
  width: 100%;
  height: 100vh;
  text-align: center;
`;

const ValuePropositionHeaderWrapper = styled("div")`
  height: 50px;
  display: flex;
  position: relative;
  z-index: ${zIndexValuePropositionHeader};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0 24px;
`;

const CRLogo = styled("img")`
  margin-top: 26px;
  display: block;
  max-height: 100px;
  max-width: 200px;
`;

const HeaderButtonWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
`;

const ValueImg = styled("img")`
  max-width: 100%;
  max-height: 55%;
  margin: 72px auto 0 auto;
  display: block;
  ${media.mobile`
    margin: 0 auto;
    max-height: 25%;
  `}
`;

const ValueIconButton = styled(IconButton)`
  &&& {
    background-color: #e7f5fb;
    color: ${({ theme }) => theme?.palette?.secondary?.main || "#00a0df"} !important;
    margin-left: 10px;

    :hover {
      background-color: ${({ theme }) => theme?.palette?.secondary?.main || "#00a0df"} !important;
      svg {
        color: white;
      }
    }
  }
`;

const MobileFooterButtonWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
`;

const ValuePropTextWrapper = styled("div")`
  margin: 0 16px;
  height: 220px;
  position: relative;
  width: auto;
`;

const ValueBodyTypography = styled(Typography)`
  max-width: 530px;
`;

const loginText = "log in";
const slide2Title = "Join for the rewards";
const slide3Title = "Stay for the community";
const slide3Body =
  "Stay in the loop with what's happening at your community, " +
  "and never miss out on a chance to connect with your neighbors";
const slide4Title = "Exclusive offers and discounts";
const slide4Body =
  "Enjoy discounts and deals to your favorite brands just for " +
  "being a resident. Exclusive monthly giveaways can also put " +
  "some extra money in your pocket!";
const slide4LinkText = "Let's jump in!";

const nextSlideStyle = {
  width: "100%",
  cursor: "pointer",
  fontWeight: "1000",
  position: "absolute",
  bottom: 0,
};
const fullWidthStyle = { width: "100%" };
const createAccountButtonStyle = {
  marginTop: "-4px",
  marginLeft: "25px",
};
const fontWeightStyle = {
  fontWeight: "1000",
};

const loginLinkStyle = { marginRight: "-8px" };

export default function ValueProposition(props) {
  const pageSlidesRef = useRef();
  const theme = useTheme();

  const { isMobile } = useWindowSize();
  const fromShortUrl = !!props.email;

  const AdvanceIcon = isMobile ? <ArrowForwardIcon /> : <ArrowDownwardIcon />;

  const welcomeText =
    `${props.noProperty ? "" : `Residents of ${props.propertyName},`} ${props.programAssets.name} ` +
    "rewards you for being a part of our community from the moment you " +
    "sign up (ps: it's free)!";
  const almostThereText =
    "It looks like you have a reward, but you haven't signed up " +
    `for ${props.programAssets.name} yet. Let's get you started!`;
  const callToActionText = fromShortUrl ? "create account" : "get started";
  const slide1Title = props.hasIncentive ? "Almost there!" : "Welcome home";
  const slide1Body = props.hasIncentive ? almostThereText : welcomeText;
  const slide2Body =
    `${props.programAssets.name} is resident life, rewarded. ` +
    "Earn points for completing daily actions and redeem those " +
    "points for rewards at your favorite brands!";

  const tracking = props.noProperty ? null : useAuthenticationTracking();
  const trackLoginButtonClick = () => {
    if (tracking) {
      tracking.trackEvent(valuePropLoginButtonTrackingAttributes(pageSlidesRef?.current?.getIndex()));
    }
  };

  const trackGetStartedButtonClick = () => {
    if (tracking) {
      tracking
        // eslint-disable-next-line max-len
        .trackEvent(valuePropGetStartedButtonTrackingAttributes(pageSlidesRef?.current?.getIndex()));
    }
  };

  const callToActionLink = props.noProperty ? "/" : "/create-account";

  useEffect(() => {
    if (tracking) {
      tracking.trackEvent(valuePropPageLoadTrackingAttributes());
    }
  }, []);

  return (
    <ValuePropositionWrapper>
      <ValuePropositionHeaderWrapper>
        <Link to="/" href="/">
          <CRLogo src={props.programAssets.logoSecondary} alt={`${props.programAssets.name}`} />
        </Link>
        <HeaderButtonWrapper>
          {!props.noProperty && (
            <Link to="/login" href="/login" style={loginLinkStyle}>
              <MaterialButton variant="text" color="secondary" onClick={trackLoginButtonClick}>
                <Typography variant="subtitle1" color="secondary" style={fontWeightStyle}>
                  {loginText}
                </Typography>
              </MaterialButton>
            </Link>
          )}
          {!isMobile && (
            <Link to={callToActionLink} href={callToActionLink}>
              <MaterialButton
                variant="contained"
                color={fromShortUrl ? "warning" : "secondary"}
                style={createAccountButtonStyle}
                onClick={trackGetStartedButtonClick}
              >
                {callToActionText}
              </MaterialButton>
            </Link>
          )}
        </HeaderButtonWrapper>
      </ValuePropositionHeaderWrapper>
      <PageSlides ref={pageSlidesRef} headerOffset={-100}>
        <PageSlides.Slide>
          <ValueImg src={props.hasIncentive ? cipImage : parkBenchImage} />
          <ValuePropTextWrapper>
            <Typography variant="h4" align="center">
              <b>{slide1Title}</b>
            </Typography>
            <Spacing margin="16px 0" display="flex" justifyContent="center">
              <ValueBodyTypography align="center">{slide1Body}</ValueBodyTypography>
            </Spacing>
            <Typography
              align="center"
              variant="subtitle1"
              color="secondary"
              style={nextSlideStyle}
              onClick={() => pageSlidesRef.current.changeIndex()}
            >
              {slide2Title}
              <ValueIconButton aria-label="next" theme={theme}>
                {AdvanceIcon}
              </ValueIconButton>
            </Typography>
          </ValuePropTextWrapper>
        </PageSlides.Slide>
        <PageSlides.Slide>
          <ValueImg src={magGlassImage} />
          <ValuePropTextWrapper>
            <Typography variant="h4" align="center">
              <b>{slide2Title}</b>
            </Typography>
            <Spacing margin="16px 0" display="flex" justifyContent="center">
              <ValueBodyTypography align="center">{slide2Body}</ValueBodyTypography>
            </Spacing>
            <Typography
              align="center"
              variant="subtitle1"
              color="secondary"
              style={nextSlideStyle}
              onClick={() => pageSlidesRef.current.changeIndex()}
            >
              {slide3Title}
              <ValueIconButton aria-label="next" theme={theme}>
                {AdvanceIcon}
              </ValueIconButton>
            </Typography>
          </ValuePropTextWrapper>
        </PageSlides.Slide>
        <PageSlides.Slide>
          <ValueImg src={workplaceImage} />
          <ValuePropTextWrapper>
            <Typography variant="h4" align="center">
              <b>{slide3Title}</b>
            </Typography>
            <Spacing margin="16px 0" display="flex" justifyContent="center">
              <ValueBodyTypography align="center">{slide3Body}</ValueBodyTypography>
            </Spacing>
            <Typography
              align="center"
              variant="subtitle1"
              color="secondary"
              style={nextSlideStyle}
              onClick={() => pageSlidesRef.current.changeIndex()}
            >
              {slide4Title}
              <ValueIconButton aria-label="next" theme={theme}>
                {AdvanceIcon}
              </ValueIconButton>
            </Typography>
          </ValuePropTextWrapper>
        </PageSlides.Slide>
        <PageSlides.Slide>
          <ValueImg src={errandsImage} />
          <ValuePropTextWrapper>
            <Typography variant="h4" align="center">
              <b>{slide4Title}</b>
            </Typography>
            <Spacing margin="16px 0" display="flex" justifyContent="center">
              <ValueBodyTypography align="center">{slide4Body}</ValueBodyTypography>
            </Spacing>
            <Typography align="center" variant="subtitle1" color="secondary" style={nextSlideStyle}>
              {slide4LinkText}
              <Link to={callToActionLink} href={callToActionLink} onClick={trackGetStartedButtonClick}>
                <ValueIconButton aria-label="add" theme={theme}>
                  <PersonAddIcon />
                </ValueIconButton>
              </Link>
            </Typography>
          </ValuePropTextWrapper>
        </PageSlides.Slide>
      </PageSlides>
      {isMobile && (
        <MobileFooterButtonWrapper>
          <Link
            to={callToActionLink}
            href={callToActionLink}
            style={fullWidthStyle}
            onClick={trackGetStartedButtonClick}
          >
            <MaterialButton variant="contained" fullWidth color={fromShortUrl ? "warning" : "secondary"}>
              {callToActionText}
            </MaterialButton>
          </Link>
        </MobileFooterButtonWrapper>
      )}
    </ValuePropositionWrapper>
  );
}

ValueProposition.propTypes = {
  email: PropTypes.string,
  hasIncentive: PropTypes.bool,
  noProperty: PropTypes.bool,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
  }).isRequired,
  propertyName: PropTypes.string,
};

ValueProposition.defaultProps = {
  email: null,
  hasIncentive: null,
  noProperty: false,
  propertyName: undefined,
};
