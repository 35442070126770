import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import ConstrictedModal from "../molecules/ConstrictedModal";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import PropertyDisabledImg from "../../assets/images/propertyDisabled.png";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import { PropertyDisabledTrackingAttributes } from "../../analytics/authenticationAnalytics";

const PropertyDisabledWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const LinkWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pick-property-link {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
  }
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

export default function PropertyDisabled({ programAssets, baseUrl }) {
  const { isMobile } = useWindowSize();
  const tracking = useAuthenticationTracking();

  useEffect(() => {
    tracking.trackEvent(PropertyDisabledTrackingAttributes());
  }, []);

  return (
    <PropertyDisabledWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </Link>
      )}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={PropertyDisabledImg} width="100%" alt="Property Disabled" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing
              padding="24px"
              width="auto"
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              {isMobile && (
                <Spacing display="flex" justifyContent="center" marginBottom="16px" marginTop="-100px">
                  <img src={PropertyDisabledImg} height="220px" alt="Property Disabled" />
                </Spacing>
              )}

              <Typography variant="h6" align="center">
                Your Account has been Disabled
              </Typography>
              <Spacing height="16" />
              <Typography variant="body2" align="center" component="div">
                {programAssets.name} has been disabled at your community. If you have questions, please contact your
                leasing office.
              </Typography>
              <Spacing height="32" />
              <LinkWrapper>
                <a href={baseUrl || "/"} className="pick-property-link">
                  <MaterialButton variant="contained" color="secondary">
                    Pick a different property
                  </MaterialButton>
                </a>
              </LinkWrapper>
            </Spacing>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && <FooterWrapper>&nbsp;</FooterWrapper>}
    </PropertyDisabledWrapper>
  );
}

PropertyDisabled.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
};
