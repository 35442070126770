import React from "react";
import PropTypes from "prop-types";

const ArrowDown = ({ style, fill, width, height, className, viewBox }) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M16.37 7.441l.893.868a.915.915 0 0 1 0 1.324l-7.807 7.594a.98.98 0 0 1-1.362 0L.284 9.633a.915.915 0 0 1 0-1.324l.891-.868a.985.985 0 0 1 1.378.016l4.613 4.707V.938c0-.52.43-.938.964-.938h1.286c.534 0 .964.418.964.938v11.226l4.613-4.707a.978.978 0 0 1 1.378-.016z"
    />
  </svg>
);

ArrowDown.propTypes = {
  style: PropTypes.shape({}),
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  viewBox: PropTypes.string,
};

ArrowDown.defaultProps = {
  style: {},
  fill: "#7E8E9F",
  width: 18,
  height: 18,
  className: "",
  viewBox: "0 0 18 18",
};

export default ArrowDown;
