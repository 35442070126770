import React from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const styles = (theme) => ({
  cssLabel: theme.typography.body1Next,
  cssFilledInput: {
    ...theme.typography.body1Next,
    backgroundColor: theme.palette.backgroundColor.main,
    "&:focus": {
      backgroundColor: theme.palette.backgroundColor.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.backgroundColor.main,
    },
    "&:not(:hover)": {
      backgroundColor: theme.palette.backgroundColor.main,
    },
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.38)",
  },
});

const MaterialTextField = React.forwardRef((props, ref) => {
  const { classes, inputLabelProps, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      InputProps={{
        classes: {
          input: `react-css ${classes.cssFilledInput}`,
          multiline: `react-css ${classes.cssFilledInput}`,
          disabled: classes.disabled,
        },
      }}
      InputLabelProps={{
        classes: {
          root: `react-css ${classes.cssLabel}`,
        },
        ...inputLabelProps,
      }}
      ref={ref}
    />
  );
});

MaterialTextField.propTypes = {
  classes: PropTypes.shape({
    cssLabel: PropTypes.string,
    disabled: PropTypes.string,
    cssFilledInput: PropTypes.string,
  }).isRequired,
  inputLabelProps: PropTypes.shape({}),
};

MaterialTextField.defaultProps = {
  inputLabelProps: {},
};

export default withStyles(styles)(MaterialTextField);
