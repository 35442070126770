import "whatwg-fetch";

import ReactOnRails from "react-on-rails";
import snakeCaseKeys from "snakecase-keys";

const credentials = "same-origin";
const csrfToken = ReactOnRails.authenticityToken();

export function buildUrl(url, parameters = {}) {
  let queryString = "";
  let newUrl = url;
  Object.keys(parameters).forEach((key) => {
    const value = parameters[key];

    if (value === undefined) return;

    if (value instanceof Array) {
      queryString += `${value.map((item) => `${key}[]=${item}`).join("&")}&`;
    } else if (value === null) {
      queryString += `${encodeURIComponent(key)}&`;
    } else {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
  });
  if (queryString.length > 0) {
    queryString = queryString.substring(0, queryString.length - 1); // chop off last "&"
    newUrl = `${url}?${queryString}`;
  }

  return newUrl;
}

/* eslint-disable no-console */

// TODO: Remove console logs if they're causing trouble
const checkResponse = (resp, allowedStatuses = []) => {
  const contentType = resp.headers.get("content-type");
  if (resp.ok || allowedStatuses.includes?.(resp.status)) {
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return resp.json();
    }
    return resp.text();
  }

  if (contentType && contentType.indexOf("application/json") !== -1) {
    return resp.json().then((err) => {
      throw err;
    });
  }

  return resp.text().then((err) => {
    throw err;
  });
};

export const buildRequestBody = (input) => {
  if (typeof input === "object" && input !== null) {
    return JSON.stringify(
      snakeCaseKeys({
        ...input,
      })
    );
  }
  return input;
};

// TODO: Remove console logs if they're causing trouble
export default async function ajaxCall(params) {
  let response;
  const headers = params.headers || {
    "X-CSRF-TOKEN": csrfToken,
    Accept: params.accept || "application/json",
    "Content-Type": params.contentType || "application/json",
  };

  if (params.method && params.method !== "GET") {
    const body = buildRequestBody(params.body);

    response = await fetch(params.url, {
      method: params.method,
      headers,
      body,
      credentials,
    })
      .then((resp) => checkResponse(resp, params.allowedStatuses))
      .catch((err) => {
        throw err;
      });
  } else {
    response = await fetch(buildUrl(params.url, params.data), {
      method: "GET",
      headers,
      credentials,
    }).then((resp) => checkResponse(resp, params.allowedStatuses));
  }

  return response;
}
