import React, { useState, forwardRef, useImperativeHandle } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { zIndexSliderIndicatorWrapper } from "@constants/zIndexConstants";
import useWindowSize from "../../hooks/useWindowSize";

const SwipeableViewsWrapper = styled("div")`
  position: relative;
  margin-top: ${(props) => props.headerOffset}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SliderIndicatorWrapper = styled("div")`
  margin: 0;
  position: absolute;
  ${(props) => (props.isMobile ? "" : "top: 50%;")}
  left: ${(props) => (props.isMobile ? "50%" : "5%")};
  ${(props) => (props.isMobile ? "bottom: 15%;" : "")}
  transform: ${(props) => (props.isMobile ? "translateX(-50%)" : "translateY(-50%)")};
  z-index: ${zIndexSliderIndicatorWrapper};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "row" : "column")};
  align-items: center;
  justify-content: center;
`;

const SliderIndicator = styled("div")`
  display: ${(props) => (props.isMobile ? "inline-block" : "block")};
  background-color: ${(props) => (props.active ? "#c3cdd8" : "#e1e6ea")};
  width: ${(props) => (props.active ? "16" : "12")}px;
  height: ${(props) => (props.active ? "16" : "12")}px;
  border-radius: 25px;
  margin-bottom: ${(props) => (props.isMobile ? "0" : "15")}px;
  margin-right: ${(props) => (props.isMobile ? "15" : "0")}px;
  cursor: pointer;
  :focus {
    outline: transparent;
  }
`;

const SlideWrapper = styled("div")`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function Slide(props) {
  return <SlideWrapper>{props.children}</SlideWrapper>;
}

const swipeableViewsWidth = { width: "100%" };

const PageSlides = forwardRef((props, ref) => {
  const [index, setIndex] = useState(0);
  const { isMobile } = useWindowSize();

  const handleChangeIndex = (changeIndex) => {
    setIndex(changeIndex);
  };

  useImperativeHandle(ref, () => ({
    changeIndex() {
      setIndex(index + 1);
    },
    getIndex() {
      return index;
    },
  }));

  return (
    <SwipeableViewsWrapper isMobile={isMobile} headerOffset={props.headerOffset}>
      <SliderIndicatorWrapper isMobile={isMobile}>
        {props.children.map((child, childIndex) => (
          /* eslint-disable react/no-array-index-key */
          <SliderIndicator
            key={childIndex}
            active={index === childIndex}
            isMobile={isMobile}
            role="button"
            tabIndex="0"
            onKeyPress={() => handleChangeIndex(index + 1)}
            onClick={() => handleChangeIndex(childIndex)}
          />
          /* eslint-enable react/no-array-index-key */
        ))}
      </SliderIndicatorWrapper>

      <SwipeableViews
        style={swipeableViewsWidth}
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
        axis={isMobile ? "x" : "y"}
        animateHeight
      >
        {props.children}
      </SwipeableViews>
    </SwipeableViewsWrapper>
  );
});

PageSlides.Slide = Slide;

PageSlides.propTypes = {
  children: PropTypes.node,
  headerOffset: PropTypes.number,
};

PageSlides.defaultProps = {
  children: null,
  headerOffset: 0,
};

Slide.propTypes = {
  children: PropTypes.node,
};

Slide.defaultProps = {
  children: null,
};

export default PageSlides;
