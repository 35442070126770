import React from "react";
import PropTypes from "prop-types";
import useWindowSize from "../../hooks/useWindowSize";

export default function withWindowSize(Component) {
  const EnhancedComponent = ({ forwardedRef, ...rest }) => {
    const { width, height, isMobile } = useWindowSize();
    return <Component ref={forwardedRef} {...rest} windowWidth={width} windowHeight={height} isMobile={isMobile} />;
  };

  EnhancedComponent.propTypes = {
    windowWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    windowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    forwardedRef: PropTypes.shape({}),
  };
  EnhancedComponent.defaultProps = {
    windowWidth: null,
    windowHeight: null,
    forwardedRef: null,
  };

  return React.forwardRef((props, ref) => <EnhancedComponent {...props} forwardedRef={ref} />);
}
