import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { styled, useTheme } from "@mui/material/styles";
import AuthTokenExpired from "@components/features/AuthTokenExpired";
import EmailConfirm from "@components/features/EmailConfirm";
import Login from "@components/features/Login";
import NewCreateAccount from "@components/features/NewCreateAccount";
import UpdateAccount from "@components/features/UpdateAccount";
import OrganicLanding from "@components/organisms/OrganicLanding";
import PasswordReset from "@components/features/PasswordReset";
import PasswordResetExpired from "@components/features/PasswordResetExpired";
import PasswordResetForm from "@components/molecules/PasswordResetForm";
import PropertyDisabled from "@components/features/PropertyDisabled";
import SentMagicLink from "@components/features/SentMagicLink";
import ValueProposition from "@components/features/ValueProposition";
import AuthBackground from "@assets/images/authBackground.svg";
import { zIndexNewAuthBackground } from "@constants/zIndexConstants";
import { media } from "@utils/style";
import usePrevious from "@hooks/usePrevious";
import useWindowSize from "@hooks/useWindowSize";
import { sendMagicLink } from "@services/usersService";

function bgStarColor(prim, sec) {
  // if primary and secondary are the same use default for star color
  if (prim === sec) {
    return "#002856";
  }
  return prim;
}

const AuthenticationWrapper = styled("div")`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100%;
  .background {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .behind {
    position: fixed;
    z-index: ${zIndexNewAuthBackground};
  }
  .background #start {
    stop-color: ${(props) => props.secondary} !important;
  }
  .background #end {
    stop-color: ${(props) => props.primary} !important;
  }
  .background .bg_star {
    fill: ${(props) => bgStarColor(props.primary, props.secondary)} !important;
  }
  a {
    text-decoration: none;
  }
`;

const CenterWrapper = styled("div")`
  display: flex;
  justify-content: center;
  height: 100%;
  ${media.mobile`
    height: unset;
  `}
`;

const DefaultPageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  ${media.mobile`
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  `}
`;

const transitionOffset = 100;

function getDirection(newPath, lastPath, isMobile) {
  const toRoot = newPath === "/";
  const fromRoot = lastPath === "/";

  if (isMobile) {
    switch (true) {
      case toRoot:
        return "left";
      case !toRoot:
        return "right";
      default:
        return "right";
    }
  } else {
    switch (true) {
      case newPath === "/value" && fromRoot:
      case !toRoot && !fromRoot && newPath !== "/value" && lastPath !== "/value":
        return "down";
      case lastPath === "/value" && toRoot:
        return "up";
      case toRoot && lastPath !== "/value":
        return "left";
      case !toRoot && newPath !== "/value":
      default:
        return "right";
    }
  }
}

function createTransform(transition, newPath, lastPath, isMobile) {
  // prevent animation when clicking logo from auth page root
  if (newPath === "/" && lastPath === "/") return undefined;

  const from = transition === "from";
  const direction = getDirection(newPath, lastPath, isMobile);
  switch (direction) {
    case "up":
      return `translate3d(0, ${from ? "-" : ""}${transitionOffset}%, 0)`;
    case "down":
      return `translate3d(0, ${from ? "" : "-"}${transitionOffset}%, 0)`;
    case "left":
      return `translate3d(${from ? "-" : ""}${transitionOffset}%, 0,0)`;
    case "right":
    default:
      return `translate3d(${from ? "" : "-"}${transitionOffset}%, 0,0)`;
  }
}

export default function Authentication(authProps) {
  const { routePrefix, programAssets, propertyDisabled, baseUrl, hasEmailAuth, useCidp, cidpUser, ...otherProps } =
    authProps;

  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const lastLocation = usePrevious(location) || { pathname: "/" };
  const { isMobile } = useWindowSize();
  const [transitions] = useTransition(
    location,
    {
      initial: { opacity: 0 },
      from: {
        opacity: 0,
        transform: createTransform("from", location.pathname, lastLocation.pathname, isMobile),
        position: "absolute",
        overflow: "hidden",
      },
      enter: {
        opacity: 1,
        transform: "translate3d(0%,0,0)",
        width: "100%",
        height: "100%",
      },
      leave: {
        opacity: 0,
        transform: createTransform("leave", location.pathname, lastLocation.pathname, isMobile),
        position: "absolute",
        overflow: "hidden",
      },
    },
    [location]
  );

  const handleLoginRedirect = (email, enrollCIDP = false) => {
    sendMagicLink({ email, propertyId: programAssets.propertyId, enrollCIDP })
      .then((data) => {
        if (data.route) {
          history.push(data.route, data.parameters);
        } else if (data.location) {
          window.location.href = data.location;
        } else {
          history.push("/link-sent", { email });
        }
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
      });
  };

  useEffect(() => {
    // sends short_url players with no users to create-account
    if (!otherProps.hasUser && otherProps.playerId && location.pathname === "/") {
      history.push("/create-account");
    }
    // sends short_url players with users to login
    if (otherProps.hasUser && otherProps.playerId && location.pathname === "/") {
      history.push("/login", { email: otherProps.email });
    }
  }, [otherProps.email, otherProps.playerId, otherProps.hasUser]);

  useEffect(() => {
    if (propertyDisabled) {
      history.push("/property-disabled");
    }
  }, []);

  const organicPath = window.location.origin.includes("community")
    ? window.location.origin
    : `${window.location.origin}/rewards`;

  return (
    <AuthenticationWrapper primary={theme.palette.primary.main} secondary={theme.palette.secondary.main}>
      <AuthBackground className="background behind" />
      {transitions(
        (
          props,
          item // eslint-disable-line no-shadow
        ) => (
          <animated.div style={props}>
            <Switch location={item}>
              <Route path="/" exact>
                <DefaultPageWrapper>
                  <OrganicLanding programAssets={programAssets} baseUrl={baseUrl} {...otherProps} />
                </DefaultPageWrapper>
              </Route>
              <Route path="/login">
                <CenterWrapper>
                  <Login
                    hasEmailAuth={hasEmailAuth}
                    routePrefix={routePrefix}
                    programAssets={programAssets}
                    useCidp={useCidp}
                    cidpUser={cidpUser}
                    handleLoginRedirect={handleLoginRedirect}
                  />
                </CenterWrapper>
              </Route>

              {useCidp && (
                <Route
                  path="/account-update"
                  render={(routeProps) => (
                    <CenterWrapper>
                      <UpdateAccount
                        routePrefix={routePrefix}
                        programAssets={programAssets}
                        handleLoginRedirect={handleLoginRedirect}
                        {...otherProps}
                        {...routeProps}
                      />
                    </CenterWrapper>
                  )}
                />
              )}

              {otherProps.playerId ? (
                <Route path="/create-account">
                  <CenterWrapper>
                    <NewCreateAccount
                      routePrefix={routePrefix}
                      programAssets={programAssets}
                      useCidp={useCidp}
                      cidpUser={cidpUser}
                      handleLoginRedirect={handleLoginRedirect}
                      {...otherProps}
                    />
                  </CenterWrapper>
                </Route>
              ) : (
                <Route
                  path="/create-account"
                  render={() => {
                    window.location = organicPath;
                  }}
                />
              )}
              <Route path="/value">
                <CenterWrapper>
                  <ValueProposition programAssets={programAssets} {...otherProps} />
                </CenterWrapper>
              </Route>
              <Route path="/link-sent">
                <CenterWrapper>
                  <SentMagicLink programAssets={programAssets} />
                </CenterWrapper>
              </Route>
              <Route path="/password-reset">
                <CenterWrapper>
                  <PasswordReset programAssets={programAssets} />
                </CenterWrapper>
              </Route>
              <Route path="/password-reset-expired">
                <CenterWrapper>
                  <PasswordResetExpired programAssets={programAssets} />
                </CenterWrapper>
              </Route>
              <Route path="/auth-token-expired">
                <CenterWrapper>
                  <AuthTokenExpired programAssets={programAssets} />
                </CenterWrapper>
              </Route>
              <Route path="/reset_password/:token">
                <CenterWrapper>
                  <PasswordResetForm routePrefix={routePrefix} />
                </CenterWrapper>
              </Route>
              <Route path="/email-confirm">
                <CenterWrapper>
                  <EmailConfirm programAssets={programAssets} />
                </CenterWrapper>
              </Route>
              <Route path="/property-disabled">
                <CenterWrapper>
                  <PropertyDisabled programAssets={programAssets} baseUrl={baseUrl} />
                </CenterWrapper>
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </animated.div>
        )
      )}
    </AuthenticationWrapper>
  );
}

Authentication.propTypes = {
  routePrefix: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    propertyId: PropTypes.number,
  }).isRequired,
  propertyDisabled: PropTypes.bool.isRequired,
  hasEmailAuth: PropTypes.bool,
  useCidp: PropTypes.bool,
  cidpUser: PropTypes.bool,
};

Authentication.defaultProps = {
  hasEmailAuth: false,
  useCidp: false,
  cidpUser: false,
};
