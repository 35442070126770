export const zIndexCommunityFeedContainer = -2;
export const zIndexActionsPageBackgroundWrapper = -2;
export const zIndexNewAuthBackground = -1;
export const zIndexDrawerToggle = 10;
export const zIndexMaterialCard = 10;
export const zIndexPerkCardOverlay = 10;
export const zIndexPerksDrawerSpinner = 50;
export const zIndexErrorBanner = 97;
export const zIndexNavBar = 98;
export const zIndexWithOverlayHOC = 98;
export const zIndexWidgetToggleButtons = 99; // NOTE: for zenDesk and Accessibe
export const zIndexAutoComplete = 100;
export const zIndexDrawerOverlay = 100;
export const zIndexDrawer = 109;
export const zIndexFeedbackBanner = 110;
export const zIndexLightBox = 1299; // under mui modal default 1300
export const zIndexConfirm = 110;
export const zIndexPopperMenu = 1300;
export const zIndexContentSelector = 110;
export const zIndexSliderIndicatorWrapper = 110;
export const zIndexValuePropositionHeader = 110;
export const zIndexUnderlinedLink = 110;
export const zIndexPollHistoryCardOverlay = 10;
export const zIndexLightBoxOverride = 1400;

export default {
  zIndexCommunityFeedContainer,
  zIndexActionsPageBackgroundWrapper,
  zIndexDrawerToggle,
  zIndexMaterialCard,
  zIndexPerkCardOverlay,
  zIndexPerksDrawerSpinner,
  zIndexErrorBanner,
  zIndexNavBar,
  zIndexWithOverlayHOC,
  zIndexWidgetToggleButtons,
  zIndexAutoComplete,
  zIndexDrawerOverlay,
  zIndexDrawer,
  zIndexFeedbackBanner,
  zIndexConfirm,
  zIndexPopperMenu,
  zIndexContentSelector,
  zIndexSliderIndicatorWrapper,
  zIndexNewAuthBackground,
  zIndexValuePropositionHeader,
  zIndexUnderlinedLink,
  zIndexPollHistoryCardOverlay,
};
