import React from "react";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

class MMRouter extends React.Component {
  constructor(props) {
    super(props);
    if (!MMRouter.history) {
      this.history = createBrowserHistory({
        basename: props.basename,
      });
      MMRouter.history = this.history;
    }
  }

  render() {
    return <Router history={MMRouter.history} {...this.props} />;
  }
}

MMRouter.propTypes = {
  basename: PropTypes.string,
};

MMRouter.defaultProps = {
  basename: undefined,
};

export default MMRouter;
