export const SCROLL_SPEED = new Map([
  ["slow", 50],
  ["medium", 100],
  ["fast", 150],
  ["faster", 270],
  ["fastest", 360],
]);

export const BUTTON_SIZES = {
  small: "small",
  medium: "medium",
  large: "large",
};

export const OMNI_SIDEBAR_WIDTH = "53px";

/*
NOTE: oldTablet is the breakpoint at which we are
switching on the mobile navigation in Navigation.jsx
*/
export const DEVICE_SIZES = {
  giant: 1440,
  desktop: 1024,
  oldTablet: 991,
  medium: 960,
  horizontalTablet: 808,
  tablet: 768,
  mobile: 660,
};

export default {
  DEVICE_SIZES,
  SCROLL_SPEED,
  BUTTON_SIZES,
};
