import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import ErrorBanner from "./ErrorBanner";
import ErrorInline from "./ErrorInline";

const initialState = {
  errorInfo: null,
  bannerOpen: false,
  eventId: undefined,
  chunkError: false,
};

function isChunkError(error) {
  const name = /ChunkLoadError/i.test(error?.name);
  const message = /Loading chunk/i.test(error?.message);
  return name || message;
}

const FIVE_MIN = 5 * 60 * 1000;
const LAST_RELOAD_TIME = "LAST_RELOAD_TIME";

function handlePageRefresh() {
  if (localStorage && localStorage?.getItem(LAST_RELOAD_TIME)) {
    const currentTime = new Date();
    const lastReloadTime = new Date(localStorage.getItem(LAST_RELOAD_TIME));
    if (currentTime - lastReloadTime > FIVE_MIN) {
      // if not reloaded in the last 5 mins reload
      localStorage.setItem(LAST_RELOAD_TIME, new Date());
      window.location.reload();
    }
  } else if (localStorage) {
    localStorage.setItem(LAST_RELOAD_TIME, new Date());
    window.location.reload();
  }
}

class ErrorBoundary extends Component {
  state = initialState;

  componentDidCatch(error, errorInfo) {
    let eventId;
    const chunkError = isChunkError(error);
    if (process.env.NODE_ENV !== "development" && !chunkError) {
      eventId = Sentry.captureException(error, { extra: errorInfo });
    } else {
      console.log(error); // eslint-disable-line
    }

    if (chunkError) {
      // reload page when chunk error
      handlePageRefresh();
    }

    this.setState({
      errorInfo,
      bannerOpen: true,
      eventId,
      chunkError,
    });
  }

  hideBanner = () => {
    this.setState({ bannerOpen: false });
  };

  renderError = () => {
    const { inline, showSVG, disableContainer } = this.props;
    if (inline) {
      return (
        <ErrorInline
          showSVG={showSVG}
          eventId={this.state.eventId}
          renderFeedback={!this.state.chunkError}
          disableContainer={disableContainer}
        />
      );
    }

    return (
      <ErrorBanner
        eventId={this.state.eventId}
        renderFeedback
        visible={this.state.bannerOpen}
        hideBanner={this.hideBanner}
        visibleTime={10000}
      />
    );
  };

  render() {
    if (this.state.errorInfo) {
      return <Fragment>{this.renderError()}</Fragment>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  inline: PropTypes.bool,
  showSVG: PropTypes.bool,
  disableContainer: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  inline: false,
  showSVG: true,
  disableContainer: false,
};

export default ErrorBoundary;
