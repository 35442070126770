import React from "react";
import ReactOnRails from "react-on-rails";
import camelCaseKeys from "camelcase-keys";
import AuthenticationApp from "../src/components/entryPoints/AuthenticationApp";
import PropertySelectApp from "../src/components/entryPoints/PropertySelectApp";

/* eslint-disable react/jsx-filename-extension */
const AuthenticationAppWithRailsContext = (props, railsContext) => () => (
  <AuthenticationApp {...{ ...camelCaseKeys(props, { deep: true }), railsContext }} />
);

/* eslint-disable react/jsx-filename-extension */
const PropertySelectAppWithRailsContext = (props, railsContext) => () => (
  <PropertySelectApp {...{ ...camelCaseKeys(props, { deep: true }), railsContext }} />
);

ReactOnRails.register({
  Authentication: AuthenticationAppWithRailsContext,
  PropertySelect: PropertySelectAppWithRailsContext,
});
