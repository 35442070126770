import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";
import { media } from "../../utils/style";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import ConstrictedModal from "../molecules/ConstrictedModal";
import Img from "../atoms/Img";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import ForgotPasswordDesktop from "../../assets/images/ForgotPasswordDesktop.png";
import ForgotPasswordMobile from "../../assets/images/ForgotPasswordMobile.png";
import LinkSentMobile from "../../assets/images/LinkSentMobile.png";
import LinkSentDesktop from "../../assets/images/LinkSentDesktop.png";
import { sendResetPassword, resendConfirmationEmail } from "../../services/usersService";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import { loginSignUpButtonTrackingAttributes } from "../../analytics";
import { PASSWORD_RESET } from "../../analytics/constants";
import ErrorText from "../atoms/ErrorText";

const ForgotPasswordWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled("div")`
  width: 100%;
  position: absolute;
  bottom: ${({ error }) => (error ? 78 : 128)}px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Circle = styled("div")`
  background-color: ${(props) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 10px;
  flex-shrink: 0;
`;

const LinkWrapper = styled("div")`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  bottom: 40px;
  ${media.mobile`
    bottom: 128px;
  `};

  .sign-in-link {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
  }
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const TypographyWithWordBreak = styled(Typography)`
  word-break: break-word;
`;

const Strike = styled("span")`
  ${({ strike }) => strike && "text-decoration: line-through;"}
`;

const step1Copy = "Send reset request to your email: ";
const nevermindButtonStyle = {
  textDecoration: "none",
  width: "100%",
  textAlign: "center",
};

const PasswordReset = ({ programAssets }) => {
  const history = useHistory();
  const tracking = useAuthenticationTracking();
  const step = (history.location.state && history.location.state.step) || 1;
  const [currentStep, setCurrentStep] = useState(step);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const email = history.location.state ? history.location.state.email : false;
  const renderTitleText = () => {
    if (currentStep === 1) {
      return (
        <Typography variant="h6" align="center">
          Don&apos;t Worry! It&apos;s super easy.
        </Typography>
      );
    }
    return (
      <Fragment>
        <Typography variant="h6" align="center">
          Request Sent!
        </Typography>
        <Spacing height="16" />
        <Typography variant="body2" align="center">
          Simply click the link in the email to create your new password!
        </Typography>
        <Spacing height="16" />
      </Fragment>
    );
  };

  const trackSignUpClick = () => {
    tracking.trackEvent(loginSignUpButtonTrackingAttributes(PASSWORD_RESET));
  };

  function sendResetEmail() {
    setLoading(true);
    sendResetPassword({ email })
      .then(() => {
        setCurrentStep(2);
        setLoading(false);
      })
      .catch((e) => {
        if (e?.errors?.email?.[0] === "must be confirmed first") {
          resendConfirmationEmail({ email }).then(() => {
            setError(
              "New account email must be confirmed before resetting password! Click the link in the email we sent you to confirm your address."
            );
            setLoading(false);
          });
        } else {
          setCurrentStep(2);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    if (!email && step !== 2) {
      history.push("/");
    }
  }, []);

  return (
    <ForgotPasswordWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />
        </Link>
      )}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img
                src={currentStep === 1 ? ForgotPasswordDesktop : LinkSentDesktop}
                width="100%"
                alt={currentStep === 1 ? "Forgot Password?" : "Link Sent!"}
              />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing padding="24px" width="auto" display="flex" flexDirection="column">
              {isMobile ? (
                <Spacing width="auto" display="flex" flexDirection="column" justifyContent="center" height="220">
                  <Img
                    alt={currentStep === 1 ? "Forgot Password?" : "Link Sent!"}
                    src={currentStep === 1 ? ForgotPasswordMobile : LinkSentMobile}
                  />
                </Spacing>
              ) : (
                <Spacing height={120} />
              )}
              {renderTitleText()}
              <Spacing height={16} />

              <ItemWrapper>
                <Circle color={currentStep === 1 ? theme.palette.secondary.main : theme.palette.lightGrey.main}>
                  1
                </Circle>
                <TypographyWithWordBreak variant="body2" color={currentStep === 1 ? "textPrimary" : "textSecondary"}>
                  <Strike strike={currentStep !== 1}>
                    {step1Copy} <b>{email}</b>
                  </Strike>
                </TypographyWithWordBreak>
              </ItemWrapper>

              <Spacing height={16} />

              <ItemWrapper>
                <Circle color={theme.palette.secondary.main}>2</Circle>
                <Typography variant="body2" color="textPrimary">
                  Click the <b>Reset Password</b> link in your email inbox.
                </Typography>
              </ItemWrapper>

              <Spacing height={16} />

              <ItemWrapper>
                <Circle color={theme.palette.secondary.main}>3</Circle>
                <Typography variant="body2" color="textPrimary">
                  Create <b>New Password.</b>
                </Typography>
              </ItemWrapper>
            </Spacing>
            {currentStep === 1 && (
              <ButtonWrapper error={!!error}>
                <MaterialButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={loading}
                  onClick={sendResetEmail}
                >
                  {loading ? "Sending Request..." : "Send Request"}
                </MaterialButton>
                <Spacing height={16} />
                <Link to="/login" href="/login" style={nevermindButtonStyle}>
                  <MaterialButton variant="text" color="secondary" fullWidth>
                    Never mind
                  </MaterialButton>
                </Link>
                <Spacing margin="0 16px" width="auto">
                  <ErrorText text={error} align="center" />
                </Spacing>
              </ButtonWrapper>
            )}
            {currentStep === 2 && (
              <LinkWrapper>
                <Link to="/login" href="/login" className="sign-in-link">
                  <MaterialButton variant="text" color="secondary">
                    Back To Sign In
                  </MaterialButton>
                </Link>
              </LinkWrapper>
            )}
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && currentStep === 1 && (
        <FooterWrapper>
          <Typography variant="caption" color="contrastPrimary">
            {`Dont have a ${programAssets.name} account?`}
          </Typography>
          <Spacing marginTop="8px" />
          <Link to="/create-account" href="/create-account">
            <MaterialButton
              variant="text"
              color={programAssets.isWhiteLabel ? "white" : "secondary"}
              onClick={trackSignUpClick}
            >
              Sign Up
            </MaterialButton>
          </Link>
        </FooterWrapper>
      )}
      {!isMobile && currentStep !== 1 && <FooterWrapper>&nbsp;</FooterWrapper>}
    </ForgotPasswordWrapper>
  );
};

PasswordReset.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    isWhiteLabel: PropTypes.bool,
  }).isRequired,
};

export default PasswordReset;
