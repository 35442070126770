import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import ConstrictedModal from "../molecules/ConstrictedModal";
import Img from "../atoms/Img";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import LinkSentMobile from "../../assets/images/LinkSentMobile.png";
import LinkSentDesktop from "../../assets/images/LinkSentDesktop.png";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import {
  emailConfirmPageLoadTrackingAttributes,
  emailConfirmBackToSignInTrackingAttributes,
} from "../../analytics/authenticationAnalytics";
import { resendConfirmationEmail } from "../../services/usersService";

const EmailConfirmWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const Circle = styled("div")`
  background-color: ${(props) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 10px;
  flex-shrink: 0;
`;

const LinkWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 20px;
  .sign-in-link {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
  }
`;

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const TypographyWithWordBreak = styled(Typography)`
  word-break: break-word;
`;

const Strike = styled("span")`
  ${({ strike }) => strike && "text-decoration: line-through;"}
`;

const step2Copy = "The magic link has been sent to your email. Click the link in your email to confirm!";

export default function EmailConfirm({ programAssets }) {
  const history = useHistory();
  const theme = useTheme();
  const tracking = useAuthenticationTracking();
  const { isMobile } = useWindowSize();
  const email = history.location.state ? history.location.state.email : null;
  const hasEmailSent = history.location.state ? history.location.state.emailSent : false;
  const [emailSent, setEmailSent] = useState(hasEmailSent);

  useEffect(() => {
    if (!email) {
      history.push("/");
    }
    if (email) {
      tracking.trackEvent(emailConfirmPageLoadTrackingAttributes());
    }
  }, []);

  const trackBackSignInClick = () => {
    tracking.trackEvent(emailConfirmBackToSignInTrackingAttributes());
  };

  const onResendConfirmationEmailClick = async () => {
    await resendConfirmationEmail({ email });
    setEmailSent(true);
  };

  return (
    <EmailConfirmWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <img src={programAssets.logoPrimary} alt={`${programAssets.name}`} width="200px" height="96px" />
        </Link>
      )}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={LinkSentDesktop} width="100%" alt="confirmation link sent" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing padding="24px" width="auto" display="flex" flexDirection="column">
              {isMobile ? (
                <Spacing width="auto" display="flex" flexDirection="column" justifyContent="center" height="220">
                  <Img src={LinkSentMobile} />
                </Spacing>
              ) : (
                <Spacing height={120} />
              )}
              <Typography variant="h6" align="center">
                Confirm Your Account
              </Typography>
              <Spacing height="16" />
              <Typography variant="body2" align="center">
                {step2Copy}
              </Typography>
              <Spacing height="32" />

              <ItemWrapper>
                <Circle color={emailSent ? theme.palette.lightGrey.main : theme.palette.secondary.main}>1</Circle>
                <TypographyWithWordBreak variant="body2" color={emailSent ? "textSecondary" : "textPrimary"}>
                  <Strike strike={emailSent}>
                    Send account confirmation to your email: <b>{email}</b>
                  </Strike>
                </TypographyWithWordBreak>
              </ItemWrapper>

              <Spacing height={16} />

              <ItemWrapper>
                <Circle color={theme.palette.secondary.main}>2</Circle>
                <Typography variant="body2" color="textPrimary">
                  Click the <b>Confirm Account</b> link in your email inbox.
                </Typography>
              </ItemWrapper>

              <Spacing height={16} />

              <ItemWrapper>
                <Circle color={theme.palette.secondary.main}>3</Circle>
                <Typography variant="body2" color="textPrimary">
                  <b>Enjoy</b> {`${programAssets.name}`}
                </Typography>
              </ItemWrapper>
            </Spacing>
            <LinkWrapper>
              <MaterialButton onClick={onResendConfirmationEmailClick} variant="contained" color="secondary">
                Resend Confirmation
              </MaterialButton>
              <Spacing height="16" />
              <Link to="/" href="/" className="sign-in-link">
                <MaterialButton onClick={trackBackSignInClick} variant="text" color="secondary">
                  Back To Sign In
                </MaterialButton>
              </Link>
            </LinkWrapper>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && <FooterWrapper>&nbsp;</FooterWrapper>}
    </EmailConfirmWrapper>
  );
}

EmailConfirm.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
  }).isRequired,
};
