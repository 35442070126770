// Events
export const PAGE_LOAD = "pageLoad";
export const CLICK = "click";
export const VIEW = "view";
export const COMPLETED = "completed";
export const SEARCH = "search";

// SSO - TOS Landing Page
export const SSO_TOS_LANDING = "SSOTOSLanding";
export const CONTINUE_BUTTON = "ContinueButton";

// CR Pages
export const MAIN_APP = "MainApp";
export const ACTIONS_LIST = "ActionsList";
export const COMMUNITY_GUIDE = "CommunityGuide";
export const PERKS = "Perks";
export const REWARDS = "Rewards";
export const COMMUNITY_FEED = "CommunityFeed";
export const NAV_BAR = "NavBar";
export const TAB_BAR = "TabBar";
export const ALBUM_PHOTO_DRAWER = "AlbumPhotoDrawer";
export const AUTHENTICATION = "Authentication";
export const LOGIN = "Login";
export const PASSWORD_RESET = "PasswordReset";
export const CREATE_ACCOUNT = "CreateAccount";
export const AUTH_TOKEN_EXPIRED = "AuthTokenExpired";
export const ORGANIC_LANDING = "OrganicLanding";
export const VALUE_PROPOSITION = "ValueProposition";
export const PROPERTY_DISABLED = "PropertyDisabled";
export const GET_STARTED_BUTTON = "GetStartedButton";
export const BENEFITS = "Benefits";
export const ACTIVITY_LIST = "ActivityList";
export const REDEEM = "Redeem";

export const SENT_MAGIC_LINK = "SentMagicLink";
export const LANDING_CREATE_ACCOUNT = "LandingCreateAccount";
export const EMAIL_CONFIRM_PAGE = "EmailConfirmPage";
export const LANDING_PROPERTY_PICKER = "LandingPropertyPicker";

// Dashboard Pages
export const DASHBOARD_RESIDENT_DIRECTORY = "DashboardResidentDirectory";
export const DASHBOARD_REVEWS = "DashboardReviewsPage";
export const DASHBOARD_FEEDBACK = "DashboardFeedbackPage";
export const DASHBOARD_ENGAGE_POSTS = "DashboardEngagePostsPage";

// Buttons
export const LOGIN_BUTTON = "LoginButton";
export const SIGN_UP_BUTTON = "SignUpButton";
export const NEXT_BUTTON = "NextButton";
export const VALUE_PROPOSITION_BUTTON = "ValuePropositionButton";
export const LETS_GO_BUTTON = "LetsGoButton";
export const BACK_BUTTON = "BackButton";
export const FORGOT_PASSWORD_BUTTON = "ForgotPasswordButton";
export const BACK_TO_SIGN_IN_BUTTON = "BackToSignInButton";
export const SEND_MAGIC_LINK_BUTTON = "SendMagicLinkButton";
export const RESEND_MAGIC_LINK_BUTTON = "ResendMagicLinkButton";
export const SEND_MAGIC_LINK_CREATE_ACCOUNT_BUTTON = "SendMagicLinkCreateAccountButton";

export default {
  ALBUM_PHOTO_DRAWER,
  PAGE_LOAD,
  CLICK,
  VIEW,
  COMPLETED,
  SEARCH,
  MAIN_APP,
  NAV_BAR,
  COMMUNITY_GUIDE,
  PERKS,
  SSO_TOS_LANDING,
  CONTINUE_BUTTON,
  AUTHENTICATION,
  LOGIN,
  CREATE_ACCOUNT,
  ORGANIC_LANDING,
  VALUE_PROPOSITION,
  GET_STARTED_BUTTON,
  LOGIN_BUTTON,
  SIGN_UP_BUTTON,
  NEXT_BUTTON,
  VALUE_PROPOSITION_BUTTON,
  LETS_GO_BUTTON,
  BACK_BUTTON,
  FORGOT_PASSWORD_BUTTON,
  EMAIL_CONFIRM_PAGE,
  BACK_TO_SIGN_IN_BUTTON,
  SEND_MAGIC_LINK_BUTTON,
  RESEND_MAGIC_LINK_BUTTON,
  DASHBOARD_REVEWS,
  BENEFITS,
  ACTIVITY_LIST,
  REDEEM,
};
