import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import { string, object, ref } from "yup";
import { useParams, useHistory } from "react-router-dom";
import AuthHeader from "./AuthHeader";
import newPasswordFormIllustration from "../../assets/images/NewPassword.png";
import Img from "../atoms/Img";
import Typography from "../atoms/material/MaterialTypography";
import ErrorText from "../atoms/ErrorText";
import MaterialButton from "../atoms/material/MaterialButton";
import MaterialTextField from "../atoms/material/MaterialTextField";
import ConstrictedModal from "./ConstrictedModal";
import Spacing from "../layouts/Spacing";
import { resetPassword } from "../../services/usersService";
import CRLogo from "../../assets/images/cr-logo-white.png";
import useWindowSize from "../../hooks/useWindowSize";

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const passwordResetSchema = object({
  password: string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,128}$/, {
      message: "must be at least 10 characters and include 1 upper case letter, 1 lower case letter, and 1 number",
      excludeEmptyString: true,
    })
    .required(),
  passwordConfirmation: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

const InputHeader = styled("div")`
  align-self: flex-start;
`;

const ResetPasswordFormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function useCheckToken(tokenValue) {
  const history = useHistory();
  useEffect(() => {
    resetPassword({ reset_password_token: tokenValue }).catch((e) => {
      // eslint-disable-next-line camelcase
      if (/invalid|expired/.test(e?.errors?.reset_password_token?.[0])) {
        history.push("/password-reset-expired");
      }
    });
  }, []);
}

const PasswordResetForm = ({ routePrefix }) => {
  const { isMobile } = useWindowSize();
  const { token } = useParams();
  const initialFormValues = {
    reset_password_token: token,
    password: "",
    passwordConfirmation: "",
  };
  const onSubmit = (values) => {
    resetPassword(values).then(() => {
      window.location = routePrefix;
    });
  };

  useCheckToken(token);

  return (
    <ResetPasswordFormWrapper>
      {!isMobile && <img src={CRLogo} alt="" width="200px" height="96px" />}
      <ConstrictedModal open>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing padding="24px" width="auto" display="flex" flexDirection="column">
              <Img src={newPasswordFormIllustration} height="100%" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <AuthHeader mainText="New Password" secondaryText={null} />
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={passwordResetSchema}
              initialValues={initialFormValues}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form noValidate>
                  <Spacing padding="0 24px" width="auto" display="flex" flexDirection="column" alignItems="center">
                    <Spacing height="16" />
                    <InputHeader>
                      <Typography variant="subtitle2">New Password</Typography>
                    </InputHeader>
                    <Spacing height="10" />
                    <Field name="password">
                      {({ field, form: { errors } }) => (
                        <Fragment>
                          <ErrorText
                            text={errors[field.name] && errors[field.name].length >= 35 && errors[field.name]}
                          />
                          <MaterialTextField
                            {...field}
                            variant="filled"
                            label={
                              errors && errors[field.name] && errors[field.name].length < 35
                                ? errors[field.name]
                                : "Password"
                            }
                            error={!!errors[field.name]}
                            placeholder="New Password"
                            type="password"
                            required
                            fullWidth
                          />
                        </Fragment>
                      )}
                    </Field>
                    <Spacing height="16" />
                    <InputHeader>
                      <Typography variant="subtitle2">New Password Confirmation</Typography>
                    </InputHeader>
                    <Spacing height="10" />
                    <Field name="passwordConfirmation">
                      {({ field, form: { errors } }) => (
                        <MaterialTextField
                          {...field}
                          variant="filled"
                          label={errors && errors[field.name] ? errors[field.name] : "Confirm Password"}
                          error={!!errors[field.name]}
                          placeholder="Confirm Password"
                          type="password"
                          required
                          fullWidth
                        />
                      )}
                    </Field>
                    <Spacing height="16" />
                    <MaterialButton
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Let&apos;s Go!
                    </MaterialButton>
                  </Spacing>
                </Form>
              )}
            </Formik>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && <FooterWrapper>&nbsp;</FooterWrapper>}
    </ResetPasswordFormWrapper>
  );
};

PasswordResetForm.propTypes = {
  routePrefix: PropTypes.string.isRequired,
};

export default PasswordResetForm;
