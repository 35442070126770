import React, { useMemo, FC } from "react";
import { v4 as generateUuid } from "uuid";

interface AuthHeaderBackgroundProps {
  primary: string;
  secondary: string;
}

const AuthHeaderBackground: FC<AuthHeaderBackgroundProps> = ({ primary, secondary }) => {
  /*
   * SVGs require unique ids to function correctly. This makes it so mulitple instances
   * of this SVG don't randomly lose styles cause unmounted svgs lose their gradient.
   */
  const id = useMemo(() => generateUuid(), [primary, secondary]);

  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 320 180"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="0%" y1="34.1796875%" x2="100%" y2="65.8203125%" id={id}>
          <stop stopColor={secondary} offset="0%" />
          <stop stopColor={primary} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Pre-Auth" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-18" transform="translate(-24.000000, -126.000000)">
          <g id="Group-19" transform="translate(24.000000, 126.000000)">
            <path
              d="M0,144.820354 C46.9242622,138.117157 86.5939949,139.117157 119.009198,147.820354 C158.90289,158.53146 204.558371,180 270.420978,180 C292.633985,180 309.160326,178.640534 320,175.921603 L320,0 L0,0 L0,144.820354 Z"
              id="Path-2-Copy"
              fill={`url(#${id})`}
            />
            <path
              d="M60.9214019,105.440423 L39.5240681,120.387989 C38.4006697,121.185943 36.8932952,121.177788 35.7785957,120.367727 C34.6638962,119.557666 34.1906466,118.126485 34.6026813,116.811578 L42.183451,91.7461294 C42.6214153,90.4622793 42.1608911,89.0433669 41.0524491,88.2614207 L20.2664677,72.4579613 C19.1818357,71.6247548 18.7371957,70.2002203 19.1552871,68.8979687 C19.5733784,67.595717 20.7641498,66.6962357 22.1310925,66.6501136 L48.2969749,66.1304641 C49.6705355,66.1206683 50.8916276,65.2536929 51.3537369,63.9601631 L59.943238,39.2309587 C60.3687678,37.9018271 61.6044118,37 63,37 C64.3955882,37 65.6312322,37.9018271 66.056762,39.2309587 L74.6462631,63.9601631 C75.1007597,65.2594394 76.3265493,66.12975 77.7030251,66.1304641 L103.868908,66.6501136 C105.23585,66.6962357 106.426622,67.595717 106.844713,68.8979687 C107.262804,70.2002203 106.818164,71.6247548 105.733532,72.4579613 L84.458469,88.2614207 C83.3500269,89.0433669 82.8895028,90.4622793 83.3274671,91.7461294 L90.9082368,116.811578 C91.2941495,118.128606 90.8084742,119.545903 89.6958951,120.349432 C88.5833161,121.152962 87.0851924,121.168408 85.9562824,120.387989 L64.5589486,105.440423 C63.4582517,104.703994 62.0220988,104.703994 60.9214019,105.440423 Z"
              id="Path-Copy-2"
              fillOpacity="0.2"
              fill="#002856"
              fillRule="nonzero"
              transform="translate(63.000000, 78.990455) rotate(-20.000000) translate(-63.000000, -78.990455) "
            />
            <path
              d="M251.34657,145.441246 L234.325963,157.331355 C233.432351,157.966091 232.233303,157.959604 231.34661,157.315237 C230.459917,156.670871 230.083469,155.532431 230.411224,154.486482 L236.441381,134.548057 C236.789762,133.526813 236.423436,132.398133 235.541721,131.77613 L219.007417,119.205197 C218.144642,118.542419 217.790951,117.409266 218.123524,116.373384 C218.456096,115.337502 219.403301,114.622006 220.490642,114.585318 L241.304412,114.17196 C242.397017,114.164168 243.36834,113.474528 243.735927,112.445584 L250.568485,92.7746263 C250.906974,91.7173625 251.889873,91 253,91 C254.110127,91 255.093026,91.7173625 255.431515,92.7746263 L262.264073,112.445584 C262.625604,113.4791 263.600664,114.171392 264.695588,114.17196 L285.509358,114.585318 C286.596699,114.622006 287.543904,115.337502 287.876476,116.373384 C288.209049,117.409266 287.855358,118.542419 286.992583,119.205197 L270.069237,131.77613 C269.187521,132.398133 268.821195,133.526813 269.169576,134.548057 L275.199734,154.486482 C275.50671,155.534118 275.120377,156.661514 274.235371,157.300685 C273.350365,157.939856 272.158676,157.952143 271.260679,157.331355 L254.240073,145.441246 C253.364518,144.85545 252.222124,144.85545 251.34657,145.441246 Z"
              id="Path-Copy-3"
              fillOpacity="0.2"
              fill="#002856"
              fillRule="nonzero"
              transform="translate(253.000000, 124.401499) rotate(-50.000000) translate(-253.000000, -124.401499) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AuthHeaderBackground;
