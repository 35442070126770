import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useWindowSize from "../../hooks/useWindowSize";
import ConstrictedModal from "../molecules/ConstrictedModal";
import Spacing from "../layouts/Spacing";
import { media } from "../../utils/style";
import AutoComplete from "../molecules/AutoCompleteReactSelect";
import Typography from "../atoms/material/MaterialTypography";
import LinkSentMobile from "../../assets/images/LinkSentMobile.png";
import LinkSentDesktop from "../../assets/images/LinkSentDesktop.png";
import Img from "../atoms/Img";

const PropertyPickerWrapper = styled("div")`
  ${media.mobile`
    min-width: 288px;
    width: 100%;
  `}
`;

const Logo = styled("img")`
  width: 100%;
  max-width: 264px;
  object-fit: contain;
`;

const CenterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoldUnderline = styled("span")`
  font-weight: bold;
  text-decoration: underline;
`;

const textFieldProps = {
  variant: "standard",
};

const propertyPickerCopy = "Pick the property here that you live at and we'll forward you along to their login page.";
const propertySelectContainerStyle = { overflowY: "auto" };

export default function PropertySelect({ programAssets, properties }) {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const [property, setProperty] = useState(null);
  const email = history.location?.state?.email;

  const handlePropertyChange = (selectedProperty) => {
    setProperty(selectedProperty);
    if (selectedProperty?.value) {
      window.location = `${selectedProperty.value}/auth/login?email=${encodeURIComponent(email)}&fromPicker=true`;
    }
  };

  return (
    <CenterWrapper>
      {!isMobile && <Logo src={programAssets.logoPrimary} alt={`${programAssets.name}`} />}
      <ConstrictedModal>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={LinkSentDesktop} width="100%" alt="Link Sent!" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing
              style={propertySelectContainerStyle}
              padding="24px"
              height="100%"
              width="auto"
              display="flex"
              flexDirection="column"
            >
              {isMobile ? (
                <Spacing width="auto" display="flex" flexDirection="column" justifyContent="center" height="220">
                  <Img alt="Link Sent!" src={LinkSentMobile} />
                </Spacing>
              ) : (
                <Spacing height={48} />
              )}
              <Typography variant="h6" align="center">
                Login to Your Property
              </Typography>
              <Spacing height={48} />
              <Typography color="primary" variant="body1">
                {propertyPickerCopy}
              </Typography>
              <Spacing height={48} />
              <PropertyPickerWrapper>
                <Typography color="primary" variant="body1">
                  <b>Select Your Property</b>
                </Typography>
                <Spacing height={8} />
                <AutoComplete
                  showDropdownIndicator
                  showIndicatorSeparator
                  windowThreshold={20}
                  onChange={handlePropertyChange}
                  placeholder="Property Name"
                  value={property}
                  options={properties}
                  textFieldProps={textFieldProps}
                  isClearable
                />
              </PropertyPickerWrapper>
              <Spacing height={16} />
              <Typography variant="body2" align="center">
                Your Email: <BoldUnderline>{email}</BoldUnderline>
              </Typography>
            </Spacing>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
    </CenterWrapper>
  );
}

PropertySelect.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
  }).isRequired,
};
