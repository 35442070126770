import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { media } from "../../utils/style";
import Typography from "../atoms/material/MaterialTypography";
import AuthHeaderBackground from "../atoms/AuthHeaderBackground";

const Wrapper = styled("div")`
  position: relative;
  overflow: hidden;
  border-top-right-radius: 4px;
  ${media.mobile`
    overflow: unset;
    border-top-right-radius: unset;
  `}
  min-width: 320px;
  max-width: 660px;

  .mainText {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .secondaryText {
    opacity: 0.7;
    position: absolute;
    top: 12%;
    right: 7%;
    text-transform: uppercase;
    a {
      color: unset;
    }
  }
`;

const AuthHeader = (props) => {
  const { mainText, secondaryText } = props;
  const theme = useTheme();
  const wrapperStyleOverrides = {
    height: props.height,
  };
  return (
    <Wrapper style={wrapperStyleOverrides}>
      <AuthHeaderBackground primary={theme.palette.primary.main} secondary={theme.palette.secondary.main} />
      <Typography variant="subtitle2" className="secondaryText" color="contrastPrimary">
        {secondaryText}
      </Typography>
      <Typography variant="h6" className="mainText" color="contrastPrimary">
        {mainText}
      </Typography>
    </Wrapper>
  );
};

AuthHeader.propTypes = {
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.string,
};

AuthHeader.defaultProps = {
  mainText: "Create Account",
  secondaryText: "Log In",
  height: "180px",
};

export default AuthHeader;
