import { useState, useEffect } from "react";
import throttle from "lodash.throttle";
import { DEVICE_SIZES } from "../constants/styleConstants";

/*
  Hook to listen for and return the current
  window inner width and inner height
  ## Usage:
  ```
  const { width, height } = useWindowSize();
  width >= 991 ? 'desktop' : 'mobile'
  ```
*/

const initialWidth = global?.window?.innerWidth || 0;
const initialHeight = global?.window?.innerHeight || 0;

const initialState = {
  width: initialWidth,
  height: initialHeight,
  isMobile: initialWidth <= DEVICE_SIZES.mobile, // true if 0
  isDesktop: initialWidth > DEVICE_SIZES.mobile, // false if 0
};

export default function useWindowSize() {
  const [size, updateSize] = useState(initialState);

  const handleResize = () => {
    const width = window?.innerWidth || 0;
    const height = window?.innerHeight || 0;
    updateSize({
      width,
      height,
      isMobile: width <= DEVICE_SIZES.mobile,
      isDesktop: width > DEVICE_SIZES.mobile,
    });
  };

  useEffect(() => {
    handleResize();
    const throttleResize = throttle(handleResize, 250);
    window.addEventListener("resize", throttleResize);
    return () => window.removeEventListener("resize", throttleResize);
  }, []);

  return size;
}
