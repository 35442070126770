import { DEVICE_SIZES } from "@constants/styleConstants";

/**
 * Generate route prefix based on railsContext
 * @param {*} railsContext
 */
export function buildRoutePrefix(railsContext) {
  const { host, railsEnv } = railsContext;

  if ((railsEnv === "development" || railsEnv === "test") && /localhost/.test(host)) {
    return "/rewards";
  }

  if (/modernmsg.com/.test(host)) {
    return "/rewards";
  }

  return "";
}

/**
 * Build full url based on railsContext
 * @param {*} railsContext
 */
export function buildUrl(railsContext) {
  const { scheme, host } = railsContext;
  const port = railsContext.port ? `:${railsContext.port}` : "";

  const prefix = buildRoutePrefix(railsContext);

  return `${scheme}://${host}${port}${prefix}`;
}

/**
 * @param {String} imgUrl
 * @param {String} defaultReturnImageUrl
 * @param {number} _qualityValue
 * @param {number} _widthValue
 * @param {number} _heightValue
 * From this: https://cdn.filestackcontent.com/PiZV7WN2TciH8f0NLGAO
 * To this: https://cdn.filestackcontent.com/quality=value:${_qualityValue}/resize=width:${_widthValue},height:${_heightValue},fit:max/compress/PiZV7WN2TciH8f0NLGAO
 * OR returns the default image url
 */
export function optimizedImageUrl(imageUrl, { fallback, quality, width, height, pjpg }) {
  if (imageUrl && imageUrl.indexOf("filestack") !== -1) {
    let resizeString = "";
    let qualityString = "";
    let pjpgString = "";
    if (width || height) {
      resizeString = `/resize=${width ? `width:${width}` : ""},${height ? `height:${height}` : ""},fit:max`;
    } else {
      const isMobile = DEVICE_SIZES.mobile > window?.innerWidth || false;
      if (isMobile) {
        resizeString = "/resize=width:400,fit:max";
      }
    }
    if (quality) {
      qualityString = `/quality=value:${quality}`;
    }
    if (pjpg) {
      pjpgString = "/output=f:pjpg";
    }
    if (imageUrl) {
      const settings = `https://cdn.filestackcontent.com${qualityString}${resizeString}${pjpgString}/rotate=deg:exif/compress`;

      let fileId = imageUrl.split("/");
      fileId = fileId[fileId.length - 1];

      return `${settings}/${fileId}`;
    }
  }

  return fallback || imageUrl;
}

export function rewardCardImageUrl(item) {
  if (!item) return "";
  return optimizedImageUrl(item.imageUrl, {
    fallback: item.assetPath,
    quality: 100,
    width: 300,
    height: 190,
  });
}

export function communityFeedImageUrl(url, hq = false) {
  return optimizedImageUrl(url, { quality: hq ? 100 : 75 });
}

export function photoAlbumImageUrl(url, hq = false) {
  return optimizedImageUrl(url, { quality: hq ? 100 : 75 });
}

export function photoAlbumQuickLinksUrl(url) {
  return optimizedImageUrl(url, { quality: 75, height: 70, pjpg: true });
}

/**
 * regex from https://www.regextester.com/94502
 * @param {String} url
 */
export function validateURL(url, allowEmpty = false) {
  if (!url && allowEmpty) {
    return true;
  }
  const validate = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;  // eslint-disable-line
  return validate.test(url);
}

export function makeUrlsLinks(urlString) {
  let newString = ` ${urlString}`.slice(1); // copy the string
  newString = newString.replace(/(https?:\/\/[^ ,]+)/gi, (match, link) => {
    const newLink = link.replace(/\/?$/, ""); // remove ending slash if there is one

    return `<a href="${newLink}" target="_blank">${newLink}</a>`;
  });
  return newString;
}

export function locationIncludes(url) {
  return window.location.href.includes(url);
}

export function locationIs(url) {
  return url === window.location.href;
}

export function navigateToIdeasWithMarketingID(slug, marketingId) {
  const routePrefix = `/dashboard/${slug}`;
  const ideasPageRoute = `${window.location.origin}${routePrefix}/engage/ideas?marketing_id=${marketingId}`;
  window.location.href = ideasPageRoute;
}

export default {
  validateURL,
  buildRoutePrefix,
  buildUrl,
  optimizedImageUrl,
  makeUrlsLinks,
  locationIs,
  locationIncludes,
  navigateToIdeasWithMarketingID,
};
