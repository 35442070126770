import LogRocket from "logrocket";

// eslint-disable-next-line import/no-mutable-exports
let status = false;
if (window.rails_env === "production" && !window.Cypress && window.location.pathname.includes("dashboard")) {
  LogRocket.init(process.env.LOGROCKET_DASHBOARD_APP_ID, {
    release: window.RELEASE || "development",
  });
  status = true;
} else if (window.rails_env === "production" && !window.Cypress) {
  LogRocket.init(process.env.LOGROCKET_CR_APP_ID, {
    release: window.RELEASE || "development",
  });
  status = true;
}

export default status;
