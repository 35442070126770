import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import Slide from "@mui/material/Slide";
import ReportProblem from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import { ifProp } from "styled-tools";
import { zIndexErrorBanner } from "@constants/zIndexConstants";
import Typography from "../../atoms/material/MaterialTypography";
import withWindowSize from "../../hocs/withWindowSize";
import Button from "../../atoms/material/MaterialButton";

const BannerWrapper = styled("div")`
  background-color: ${(props) => props.backgroundColor};
  z-index: ${zIndexErrorBanner};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${ifProp({ isMobile: true }, "8px 0px 8px 8px", "8px 0px 8px 36px")};
`;

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const styles = (theme) => ({
  checkedRoot: {
    fill: theme.palette.common.white,
    marginRight: "4px",
  },
});

const hide = (hideBanner) => {
  setTimeout(() => {
    hideBanner();
  }, 10000);
};

const buttonStyle = { color: "#FFF", margin: "0 1rem" };
const titleStyle = { margin: "1rem" };
const mobileTitleStyle = { margin: "0.5rem" };
const mobileMessageStyle = { lineHeight: "normal" };

const titleText = "Oops!";
const messageText = "Looks like something went wrong.";
const mobileMessageText = "Try refreshing.";
const mobileReportText = "Try refreshing or report your error";
const development = process.env.NODE_ENV === "development";

function ErrorBanner(props) {
  const { visible, hideBanner, windowWidth, renderFeedback, classes, eventId } = props;
  const theme = useTheme();

  if (visible) hide(hideBanner);

  const icon = <ReportProblem classes={{ root: classes.checkedRoot }} />;

  const isMobile = parseInt(windowWidth, 10) <= 991;
  const refreshPage = () => window.location.reload();
  const mobileErrorText = renderFeedback ? mobileReportText : mobileMessageText;
  const onLinkClick = () => {
    if (renderFeedback && !development) {
      Sentry.showReportDialog();
      return;
    }
    window.location.reload();
  };

  const showReportDialog = () => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.showReportDialog({ eventId });
    } else {
      // eslint-disable-next-line no-alert
      alert("Development Error: No Sentry request made");
    }
  };

  let banner = (
    <BannerWrapper backgroundColor={theme.palette.alert.main}>
      <ContentWrapper>
        {icon}
        <Typography style={titleStyle} variant="h5" color="contrastPrimary">
          {titleText}
        </Typography>
        <Typography variant="body1" color="contrastPrimary">
          {messageText}
        </Typography>
        <Button style={buttonStyle} onClick={refreshPage} variant="outlined" color="inherit">
          <Typography variant="caption" color="contrastPrimary">
            Refresh the Page
          </Typography>
        </Button>
        {renderFeedback && (
          <Button style={buttonStyle} onClick={showReportDialog} variant="outlined" color="inherit">
            <Typography variant="caption" color="contrastPrimary">
              Report your error
            </Typography>
          </Button>
        )}
      </ContentWrapper>
      <IconButton onClick={hideBanner} size="large">
        <CloseIcon />
      </IconButton>
    </BannerWrapper>
  );

  if (isMobile) {
    banner = (
      <BannerWrapper variant="alert" isMobile>
        <ContentWrapper>
          {icon}
          <Typography style={mobileTitleStyle} variant="body1" color="contrastPrimary">
            {titleText}
          </Typography>
          <Typography style={mobileMessageStyle} variant="caption" color="contrastPrimary">
            {messageText}
            <span role="button" tabIndex={0} onClick={onLinkClick} onKeyPress={onLinkClick}>
              {mobileErrorText}
            </span>
          </Typography>
        </ContentWrapper>
        <IconButton onClick={hideBanner} size="large">
          <CloseIcon />
        </IconButton>
      </BannerWrapper>
    );
  }

  return (
    <Slide direction="down" in={visible} mountOnEnter unmountOnExit>
      {banner}
    </Slide>
  );
}

ErrorBanner.propTypes = {
  visible: PropTypes.bool,
  classes: PropTypes.shape({
    checkedRoot: PropTypes.string,
  }).isRequired,
  hideBanner: PropTypes.func.isRequired,
  windowWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  renderFeedback: PropTypes.bool.isRequired,
  eventId: PropTypes.string,
};

ErrorBanner.defaultProps = {
  visible: false,
  eventId: undefined,
};

export default withWindowSize(withStyles(styles)(ErrorBanner));
