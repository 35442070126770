import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { Link, useHistory } from "react-router-dom";
import Typography from "../atoms/material/MaterialTypography";
import MaterialButton from "../atoms/material/MaterialButton";
import MaterialTextField from "../atoms/material/MaterialTextField";
import ConstrictedModal from "../molecules/ConstrictedModal";
import { media } from "../../utils/style";
import Img from "../atoms/Img";
import Spacing from "../layouts/Spacing";
import useWindowSize from "../../hooks/useWindowSize";
import { sendMagicLink } from "../../services/usersService";
import PasswordResetExpiredImage from "../../assets/images/PasswordResetExpired.png";
import SorryWeMissedYouImage from "../../assets/images/sorrywemissedyou.png";
import { useAuthenticationTracking } from "../../contexts/AuthenticationContext";
import {
  loginAuthTokenExpiredPageLoadTrackingAttributes,
  loginAuthTokenExpiredMagicLinkResendTrackingAttributes,
} from "../../analytics";

const passwordResetExpiredSchema = object({
  email: string().email().required(),
});

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 50px;
`;

const CenterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const InputHeader = styled("div")`
  align-self: flex-start;
`;

const ButtonWrapper = styled("div")`
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  bottom: 40px;
  ${media.mobile`
    bottom: 128px;
  `}
`;

const FullWidthLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled("img")`
  display: block;
  margin-bottom: 16px;
  max-height: 100px;
  max-width: 200px;
`;

const tokenExpiredText = "We knocked, but no one was home. Don't worry, we can send you another link!";

const AuthTokenExpired = ({ programAssets }) => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const emailInstructionsText = `Be sure to use the email address attached to your ${programAssets.name} account`;
  const onSubmit = (values) => {
    const params = { email: values.email, propertyId: programAssets.propertyId };
    sendMagicLink(params);
    history.push("/link-sent", params);
  };
  const tracking = useAuthenticationTracking();

  useEffect(() => {
    tracking.trackEvent(loginAuthTokenExpiredPageLoadTrackingAttributes());
  }, []);

  const trackOnRequestResendLink = () => {
    tracking.trackEvent(loginAuthTokenExpiredMagicLinkResendTrackingAttributes());
  };

  return (
    <CenterWrapper>
      {!isMobile && (
        <Link to="/" href="/">
          <Logo src={programAssets.logoPrimary} alt={`${programAssets.name} logo`} />
        </Link>
      )}
      <ConstrictedModal open>
        <ConstrictedModal.ModalContainer>
          <ConstrictedModal.ModalLeft>
            <Spacing
              paddingLeft="32px"
              paddingRight="32px"
              width="auto"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <img src={SorryWeMissedYouImage} width="100%" alt="token expired" />
            </Spacing>
          </ConstrictedModal.ModalLeft>
          <ConstrictedModal.ModalRight>
            <Spacing padding="24px" width="auto" display="flex" flexDirection="column">
              {isMobile ? (
                <Spacing width="auto" display="flex" flexDirection="column" height="220">
                  <Img src={PasswordResetExpiredImage} />
                </Spacing>
              ) : (
                <Spacing height={120} />
              )}

              <ItemWrapper>
                <Typography variant="h6" color="textPrimary">
                  Login Link Expired
                </Typography>
                <Spacing height={16} />
                <Typography variant="body2" color="textPrimary">
                  {tokenExpiredText}
                </Typography>
              </ItemWrapper>
              <Spacing height={20} />
              <InputHeader>
                <Typography variant="subtitle2">Confirm Email</Typography>
              </InputHeader>
              <Spacing height={8} />
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={passwordResetExpiredSchema}
                initialValues={{ email: "" }}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form noValidate>
                    <Spacing width="auto" display="flex" flexDirection="column">
                      <Field name="email">
                        {({ field, form: { errors } }) => (
                          <MaterialTextField
                            {...field}
                            variant="filled"
                            label={errors && errors[field.name] ? errors[field.name] : "Email Address"}
                            error={!!errors[field.name]}
                            placeholder="Email Address"
                            type="email"
                            required
                            fullWidth
                          />
                        )}
                      </Field>
                      <Spacing height={16} />
                      <Typography variant="body2" color="textSecondary">
                        <i>{emailInstructionsText}</i>
                      </Typography>
                      <ButtonWrapper>
                        <MaterialButton
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={isSubmitting}
                          fullWidth
                          onClick={trackOnRequestResendLink}
                        >
                          Re-Send Request
                        </MaterialButton>
                        <Spacing height={16} />
                        <FullWidthLink to="/" href="/">
                          <MaterialButton fullWidth variant="text" color="secondary">
                            Back To Homepage
                          </MaterialButton>
                        </FullWidthLink>
                      </ButtonWrapper>
                    </Spacing>
                  </Form>
                )}
              </Formik>
            </Spacing>
          </ConstrictedModal.ModalRight>
        </ConstrictedModal.ModalContainer>
      </ConstrictedModal>
      {!isMobile && <FooterWrapper>&nbsp;</FooterWrapper>}
    </CenterWrapper>
  );
};

AuthTokenExpired.propTypes = {
  programAssets: PropTypes.shape({
    name: PropTypes.string,
    logoPrimary: PropTypes.string,
    logoSecondary: PropTypes.string,
    propertyId: PropTypes.number,
  }).isRequired,
};

export default AuthTokenExpired;
